import { useRef } from "react";
import { useGlobalContext } from "../../../controller/context_api";
import ChangeQuestion from "../changeQuestion";
import QuestionTxt from "./questiontxt";

const QuestionGroup1 = () => {
  const { questions, translateposition } = useGlobalContext();

  // console.log(questions)
  // console.log(translateposition);
  return (
    <div className="test_question">
      {/* display question group */}
      <article className="test_question_body">
        {questions.map((questions, index) => (
          <div
            className="test_single_question"
            key={index}
            style={{
              right: `${index * 100}%`,
              transform: `translateX(${translateposition}%)`,
            }}
          >
            <span>Q{index + 1}</span>
            <QuestionTxt props={{ questions, index }} />
          </div>
        ))}
      </article>
      {/* control next question group */}
      <ChangeQuestion />
    </div>
  );
};

export default QuestionGroup1;
