import { useEffect, useState, useMemo } from "react";
import Navigation from "../navigation";
import dashmenu from "../../model/dashmenu.json";
import DashboardMenu from "./dashboard_menu";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../controller/context_api";
import Loading from "../loading";
import DuoIcon from "@material-ui/icons/Duo";
import Dashmenu from "../../views/mobile_dashnav";
import Dashdrop from "../../views/dashdrop";
import QuizTxt from "./testQuestions/quiztxt";
//import questions from "../../model/drillquestion.json"
import axios from "../axios";
import Preloader from "../init_loading";

const QuizDashboard = () => {
  const {
    islogin,
    isLoading,
    issubscribed,
    SubmitQuiz,
    quizinfo,
    //setQuizallow,
    totalquizscore,
    q_isload,
    setQ_isload,
  } = useGlobalContext();
  const history = useNavigate();

  const [isauth, setIsauth] = useState(false);
  useMemo(() => {
    if (issubscribed !== false) {
      setIsauth(true);
    }
  }, [issubscribed]);

  useEffect(() => {
    if (isauth) {
    } else {
      history("/dashboard", { replace: true });
    }
  }, []); ///uncomment this

  const [questions, setQuestions] = useState([]);
  const [question_id, setQuestion_id] = useState("");

  useEffect(async () => {
    setQ_isload(true);
    await axios
      .get("/getquiz")
      .then((res) => {
        //console.log(res.data.quiz)
        setQ_isload(false);
        setQuestions(res.data.quiz);
        setQuestion_id(res.data.quiz_id);
        const id = res.data.quiz_id;
        // console.log(quizinfo,id)
      })
      .catch((err) => {
        setQ_isload(false);
        console.log(err);
      });
  }, []);

  const isquiz = questions?.length > 0;
  //const quizallow = quizinfo?.quiz_id === question_id;
  //console.log(quizinfo?.quiz_id,question_id)
  // const [isquiz,setIsquiz] = useState(false)  /// change to false

  // useEffect(()=>{
  //     if( questions?.length!== 0  ){
  //         setIsquiz(true)
  //     }else{setIsquiz(false)}
  //     // console.log(questions)
  // },[questions])
  const [quizallow, setQuizallow] = useState(false);
  useMemo(() => {
    if (quizinfo?.quiz_id == question_id) {
      setQuizallow(true);
    }
    //console.log(questions)
  }, [question_id, quizinfo]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <section className="dash_container">
          <Navigation />
          <Dashmenu color={"rgb(1,1,26)"} />
          <Dashdrop />

          {q_isload && (
            <section className="log_loading">
              <Preloader />
            </section>
          )}
          {/* ////////////////////////////////////////////////////////////////// */}
          <article className="dash_body">
            <DashboardMenu />
            {/* /////////////////right content/////////////////////////////////// */}
            <div className="dash_l_content profile_l_cont">
              <article className="profile_title">Continous quiz</article>

              {isquiz ? (
                <section className="c-quiz-body-cont">
                  {quizallow ? (
                    <article className="attempt-modal-cont">
                      <div className="attempt-modal">
                        <div className="attempt_d_s">
                          <div className="attempt_d_txt">
                            Congratulation you have attempted all quiz. Check
                            back later to get a new one
                          </div>
                        </div>

                        <div className="attempt_s_s">
                          <span className="attempt_s_score">Score</span>
                          <span className="attempt_s_num">
                            {totalquizscore || quizinfo?.score}
                          </span>
                        </div>
                      </div>
                    </article>
                  ) : (
                    <>
                      <article className="c-quiz-body">
                        {questions?.map((questions, index) => {
                          const id = index;
                          const _id = question_id;
                          console.log(id);
                          return (
                            <div
                              className="test_single_question t-c-single"
                              key={index}
                            >
                              <span>Q{index + 1}</span>
                              <QuizTxt props={{ questions, index, _id }} />
                            </div>
                          );
                        })}
                      </article>
                      {/* -------------------------------- */}
                      <article className="c-quiz-action">
                        <div
                          className="next_questions c-qiz-color"
                          onClick={SubmitQuiz}
                        >
                          Submit
                        </div>
                      </article>
                    </>
                  )}
                </section>
              ) : (
                <article className="no-quiz">
                  <div>No quiz today</div>
                </article>
              )}
              {/* ------------------------------------- */}
            </div>
          </article>
        </section>
      )}
    </>
  );
};

export default QuizDashboard;
