import { useGlobalContext } from "../../../controller/context_api";


const QuestionTxt = (props)=>{
   
const questions = props.props.questions
const answer = questions?.answer
const id = props.props.index
const _id = questions?._id;
const {CheckAnswer} = useGlobalContext()
const htmlQuestion = questions?.question
// console.log(id); 
    return (
        <article className="test_quiz" >
            <div className="question_text" dangerouslySetInnerHTML={{ __html: htmlQuestion }}/>  
            <div className="question_options" >

                <form className="question_options_form">
                  
                { questions?.options.map((option, index)=>{
                    
                return(
                    <div className="single_options" key={index}> 
                  <input type="radio" id={index} name="option"  value={option} onClick={(e)=>CheckAnswer({e,answer,id,_id})}/>
                  <label htmlFor="opp">{option}</label>
                  </div>
                )
                })}
                  
                </form>


            

            </div>

            </article>
    )
}

export default QuestionTxt