
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LaunchIcon from '@material-ui/icons/Launch';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useGlobalContext } from '../../controller/context_api';
import axios from '../axios';
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Init from '../init_loading'
//import got from 'got'

import Axios from 'axios';
import {v4 as uuid} from 'uuid'
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
// -------------------------------------------------

const Secure_payment =()=>{


const history = useNavigate()
const {isload,setisload} = useGlobalContext()
const [ismodalopen,setIsmodalopen]=useState(false);
const [iserr,setIserr]=useState(false)
const [err,setErr]=useState("")
const name = useParams().id
const [amount,setamount] =  useState('')
const [tag,settag] =  useState('')
const Name = name.substring(0,1).toUpperCase() + name.substring(1,10)
const Amount =amount.substring(0,1) +','+ amount.substring(1,10)+'.00'
useEffect(()=>{
if(name==='basic'){setamount('3100');settag('jaex_basic')}else
if(name==='premium'){setamount('4600');settag('jaex_premium')}else
if(name==='pro'){setamount('7000');settag('jaex_pro')}
},[])

const Cancel = ()=>{
history('/dashboard',{replace:true})
}

// --------------------------------
const [value,setvalue]=useState('')
const [isvalue,setisvalue]=useState(false)
const handleChange = (e)=>{
  setIserr(false);
setvalue(e.target.value)
const check = value.split('').includes('@')
if(check){setisvalue(true)}else{setisvalue(false)}
}

const [user,setuser]= useState('')
// -------------------------------------------------
const getUser = async()=>{
const u_email = value.toLowerCase().trim();
if(u_email == ""){setIserr(true);setErr("Please enter your email"); return}
if (u_email.split("").includes("@")){
  setisload(true)
  await axios
    .get(`/getuser/${u_email}`)
    .then((res) => {
      setuser(res.data);
      setisload(false);
      setIsmodalopen(true);
    })
    .catch((err) => {
      console.log(err);
      setisload(false);
      setIserr(true);
      setErr("Please enter a registered email");
    });
}else{setIserr(true);setErr("Please enter a valid email");}
  
}



    const config = {
      public_key: "FLWPUBK-f35145b799cb10ed69e32875e64bf44e-X",
      tx_ref: `${tag + uuid()}`,
      amount: amount,
      currency: "NGN",
      payment_options: "card",
      redirect_url: "https://www.jaextechnology.com.ng/payment_confirmation",

      customer: {
        email: user.email,

        name: `${user.first_name + " " + user.last_name}`,
      },
      customizations: {
        title: "Jaex Technology",
        description: "subscription plan",
        logo: "https://firebasestorage.googleapis.com/v0/b/jaex-b9931.appspot.com/o/logo%2Flogo2.ico?alt=media&token=32f72362-a8b9-4b28-908c-901aea46a41d",
      },
    };
//}

const handleFlutterPayment = useFlutterwave(config); //uncomment

const Makepayment =()=>{

  handleFlutterPayment({
            callback: (response) => {
               console.log(response);
                //closePaymentModal() // this will close the modal programmatically
            },
            onClose: () => {
                //history('/')
            },
          });
} 

const payop = useRef(null)
const showPay=(e)=>{
  e.preventDefault()
  const pay_op = payop.current
  pay_op.classList.toggle("hide-pay-option")
}

// Test MasterCard PIN authentication
//  Card number: 5531 8866 5214 2950
//  cvv: 564
//  Expiry: 09/32
//  Pin: 3310
//  OTP: 12345

// Card number: 4556052704172643
//   cvv: 899
//   Expiry: 09/32
//   Pin: 3310
//   OTP: 12345

return (
  <article className="secure_pay">
    {/* ---------------------------------------------- */}
   { ismodalopen&& <article className="pay_modal">
      <div className="pay_modal_item">
        <div className="pay_modal_item1">
          <p className="pay_modal_h1">Payment Summary</p>
          <p className="pay_modal_p">
            Username:<span>{user?.email}</span>
          </p>
          <p className="pay_modal_p">
            Fullname:
            <span>
              {user?.first_name} {user?.last_name}
            </span>
          </p>
          <p className="pay_modal_p2">
            Amount:<span>{Amount}</span>
          </p>
        </div>
        <div className="pay_modal_item_bottons">
          <button className="pay_modal_btn1" onClick={Makepayment}>
            Pay
          </button>
          <button
            className="pay_modal_btn2"
            onClick={() => setIsmodalopen(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </article>}
    {/* -------------------------- */}
    {isload && (
      <section className="log_loading">
        <Init />
      </section>
    )}
    {/* ------------------------- */}
    <div className="pay_desc_cont">
      <article
        className="pay_desc"
        style={{
          backgroundImage: `url('../../../../graphics/paybg.png')`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="pay_head">
          {" "}
          <img src="../../../../graphics/logo1.ico" alt="jaex" />
        </div>
        <div className="pay_body">
          <article className="pay_body_item">
            <div className="pay_body_tag">Plan</div>
            <div className="pay_body_desc">{Name}</div>
          </article>
          {/* --------------------- */}
          <article className="pay_body_item">
            <div className="pay_body_tag">Amount</div>
            <div className="pay_body_desc">{Amount}</div>
          </article>
        </div>
        <div className="pay_foot" onClick={Cancel}>
          <ArrowBackIcon />
          Cancel your payment
        </div>
      </article>
    </div>
    {/* ------------------------------ */}
    <div className="pay_form">
      <div className="pay_input_div">
        {" "}
        <input
          type="text"
          name="email"
          value={value}
          placeholder="youremail@email.com"
          onChange={handleChange}
        />{" "}
        {/* {isvalue && (
          <IconButton >
            <ArrowForwardIcon
              style={{ fontSize: "16px", color: "rgb(161,207,57)" }}
            />
          </IconButton>
        )}{" "} */}
      </div>
      {/* ---------------------- */}
      {/* <div className="pay_input_div">{user.first_name}</div>*/}
      {/* ---------------- */}
      {/* <div className="pay_input_div">{user.last_name} </div>  */}
        {iserr&&<p className='pay_error'>{err}</p>}
      <div className="pay_init" onClick={getUser}>
        Proceed to pay{" "}
      </div>
      {/* ------------------------------- */}
      <article className="pay-option" ref={payop}>
        <button className="pay-option-button" onClick={showPay}>
          Pay via transfer
        </button>
        <div className="pay-option-guide">
          <ul>
            <li>Currency NGN</li>
            <li>
              Transfer method - Nigeria mobile banking, Nigeria SMS banking
            </li>
            <li>Processing time takes about 24hr to be completed</li>
          </ul>
          <div className="pay-option-desc">
            <ErrorOutlineIcon />
            Make sure you add your email address, and full name on the payment
            description. After making payment, send a copy of the receipt to{" "}
            <span>jaex.technology.inc@gmail.com</span> for verification. Thanks.
          </div>
          <div className="pay-option-info">
            <p>Jaex Technology</p>
            <p>0752707568</p>
            <p>Bank: Guaranty trust bank</p>
          </div>
        </div>
      </article>
    </div>
  </article>
);
}

export default Secure_payment