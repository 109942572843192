import { Checkbox } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../../controller/context_api"
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloseIcon from '@material-ui/icons/Close';
import axios from '../axios'
const Quizmodal = (props)=>{

const info = props.props;
const htmlQuestion = info?.question; 
// console.log(info);
const c_course = info.course;
const c_topic = info.topic

const {userfname,setismodalOpen, useremail, Updateuser} = useGlobalContext()
// const userName = userfname.toLocalcase()

const closeModal = ()=>{
setismodalOpen(false)
    
}
const answer = info?.answer;
const [option,setOption] = useState('')
const [issuccess,setIssuccess] = useState(false)
const [isfailure,setIsfailure] =useState(false)

useEffect(()=>{
setTimeout(()=>{
  setIssuccess(false)  
},3000)

},[issuccess])

useEffect(()=>{
setTimeout(()=>{
  setIsfailure(false)  
},3000)

},[isfailure])


const CheckAnswer = (e)=>{
setOption(e.currentTarget.value)
}

const submitQuiz = async()=>{
if(answer == option){
setIssuccess(true)

await axios.post("/updateuser",{
    username:useremail,
  subject:c_course,
  topic:c_topic
}).then((res)=>{
   Updateuser()
}).catch((err)=>{
    console.log(err)
})

}else{
  setIsfailure(true) 
  setOption('') 
}

}


    return (
      <section className="quiz_section">
        <article className="quiz_section_body">
          {/* ------------------------------------ */}
          {issuccess && (
            <article className="quiz_success">
              <div>
                <div className="quiz_s_icon">
                  <CheckCircleOutlineIcon />
                </div>
                <div className="quiz_s_txt">
                  Congratulation {userfname} you passed the quiz
                </div>
              </div>
            </article>
          )}
          {/* ----------------------------------------- */}
          {isfailure && (
            <article className="quiz_success">
              <div>
                <div className="quiz_c_icon">
                  <CloseIcon />
                </div>
                <div className="quiz_c_txt">
                  Opps! {userfname} you failed the quiz, please try again
                </div>
              </div>
            </article>
          )}
          {/* ----------------------------------------- */}

          <div className="quiz_parent">
            <div className="quiz_header">
              {" "}
              Hello <span>{userfname}</span> welcome to Jaex continous
              assessment quiz
            </div>

            <div className="quiz_question">
              <div>Question</div>
              <p dangerouslySetInnerHTML={{ __html: htmlQuestion }} />
            </div>

            <form className="quiz_options">
              {info?.options.map((option, index) => {
                return (
                  <div className="single_options" key={index}>
                    <input
                      type="radio"
                      id={index}
                      name="option"
                      value={option}
                      onClick={CheckAnswer}
                    />
                    <label htmlFor="opp">{option}</label>
                  </div>
                );
              })}
            </form>
          </div>
          {/* ------------------------------------------- */}
          <div className="quiz_action">
            <div className="quiz_c_button" onClick={closeModal}>
              Close modal
            </div>

            <div className="quiz_s_button" onClick={submitQuiz}>
              Submit quiz
            </div>
          </div>
        </article>
      </section>
    );

}

export default Quizmodal
