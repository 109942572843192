import { ArrowBack, ArrowBackIos,QuestionAnswer,VideoLibrary,LibraryBooks,} from "@material-ui/icons"
import DuoIcon from '@material-ui/icons/Duo';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import SchoolIcon from '@material-ui/icons/School';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

import { Link } from "react-router-dom"
import { useGlobalContext } from "../../controller/context_api"
import Adminpages from  './admin.json'
const AdminMenu = ()=>{
const {Setadminposition} = useGlobalContext()

const changeMenu = (e)=>{
const id = e.currentTarget.dataset.id;
if(id == 1){Setadminposition(0)}else
if(id == 2){Setadminposition(100)}
 else
if(id == 3){Setadminposition(200)}
else
if(id == 4){Setadminposition(300)}
else
if(id == 5){Setadminposition(400)}
else
if(id == 6){Setadminposition(500)}
else
if(id == 7){Setadminposition(600)}

}

    return( 

        <section className="admin_menu">
            <article className="admin_menu_container">
            {Adminpages.map((pages,index)=>{

                return(<div className="admin_menu_item" key={index} onClick={changeMenu} data-id={index+1}>
                {index==0&&<DuoIcon/>||index==1&&<EventAvailableIcon/>||index==2&&<LibraryBooks/>||index==3&&<SupervisorAccountIcon/>||index==4&&<LocalLibraryIcon/>||index==5&&<BeenhereIcon/>||index==6&&<SchoolIcon/> }
                 {pages.name}
                </div>)
            })}

            </article>
            <Link to="/" className="admin_menu_button_a"><article className="admin_menu_button"><ArrowBackIos/> Back home</article></Link>
        </section>
    )
}

export default AdminMenu