import { useGlobalContext } from "../../controller/context_api"
import CMSquiz from './cmsform/cmsquiz'
const Quiz = ()=>{
const {adminposition} = useGlobalContext()

    return(
        <article className="admin_form_component" style={{right:"-400%",transform:`translateX(-${adminposition}%)`}}>
            <CMSquiz/>
        </article>
    )
}
export default Quiz  