import { useGlobalContext } from "../../controller/context_api"
import CMSbook from './cmsform/cmsbook'

const Book = ()=>{
const {adminposition} = useGlobalContext()

    return(
        <article className="admin_form_component" style={{right:"-200%",transform:`translateX(-${adminposition}%)`}}>
            <CMSbook/>
        </article>
    )
}
export default Book 