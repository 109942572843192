import { ArrowBackIos } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Navigation from "./views/navigation";

const Notfound = () => {
  return ( 
    <section className="notfound">
      <Navigation />
      <article className="notfound_item">
        <div className="notfound_image">
          <img src="../../../../graphics/notfound.ico" alt="notfound" />
        </div>
        <div className="notfound_text">404</div>
        <Link to="/" className="notfound_button">
          <ArrowBackIos /> Back home
        </Link>
      </article>
    </section>
  );
};

export default Notfound;
