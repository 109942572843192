
import { AttachFile, CloudUploadOutlined, ExpandMore } from "@material-ui/icons"
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { useState } from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import axios from '../../axios'
import { useGlobalContext } from "../../../controller/context_api";

const CmsTestimony = ()=>{
const {setIsLoading,setisload} = useGlobalContext()

  const [name, setname] = useState("name");
  const [agregate, setagregate] = useState("0");
  const [desc, setdesc] = useState("");
  // //------------form error
  const [iserror, setisError] = useState(false);
  const [error, setError] = useState("");
  const [issuccess, setisSuccess] = useState(false);
  const [success, setSuccess] = useState("");

  const handleInputs = (e) => {
    setisError(false)
      setisSuccess(false)
    const name = e.currentTarget.name;

    switch (name) {
      case "description":
        setdesc(e.currentTarget.value);
        break;
      case "name":
        setname(e.currentTarget.value);
        break;
      case "agregate":
        setagregate(e.currentTarget.value);
        break;
    }
  };

  const Submit = async(e) => {
    e.preventDefault();
    if(name=="name"){setisError(true); setError("Please enter a name")}else if(desc == ''){setisError(true); setError("Please provide a description")}else if(agregate== '0'){setisError(true); setError("Please provide agregate")} else {
      setIsLoading(true)
console.log(name, agregate, desc);
await axios.post('/testimonies',{
  name:name,
  agregate:agregate,
  description:desc
}).then((res)=>{
  setIsLoading(false)
  setisSuccess(true)
  setSuccess(res.data.msg)
  setTimeout(()=>{setisSuccess(false);
  setname("name");setagregate("0"); setdesc("")},3000)
}).catch((err)=>{
setIsLoading(false)
  setisError(true); 
  setError("Failed to upload testimonies")
})


    }
  };

  return (
    <article className="cms_videoform_cont">
      <div className="cms_header">Testimony</div>
      {/* ---------------error------------------------- */}
      <div className="err_div">
        {iserror && (
          <article className="error">
            <ErrorOutlineIcon /> {error}
          </article>
        )}
      </div>
      <div className="err_div">
        {issuccess && (
          <article className="success">
            <CheckCircleOutlineIcon /> {success}
          </article>
        )}
      </div>
      {/* ----------------------------------------------------------- */}
      <form className="cms_form">
        {/* -------------------------------------------- */}
        <div className="cms_form_div">
          <input type="text" name="name" value={name} onChange={handleInputs} />
        </div>
        {/* ------------------------------------------------------- */}
        <div className="cms_form_div">
        <textarea
          name="description"
          onChange={handleInputs}
          placeholder="Description"
          className="single_note"
          maxLength="70"
        ></textarea>
        </div>
        {/* ------------------------------------------------------- */}
        <div className="cms_form_div">
          <input
            type="text"
            name="agregate"
            value={agregate}
            onChange={handleInputs}
          />
        </div>
        {/* ------------------------------------------------------- */}
        <div className="cms_buttons" onClick={Submit}>
          <ArrowRightAltIcon />
          Upload
        </div>
      </form>
    </article>
  );
}

export default CmsTestimony
