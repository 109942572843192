
const planA= [
    {"id":1,
    "name":"Three months plan"},
{"id":2,
    "name":"Access to chatroom"},
{"id":3,
    "name":"Access to test-drilling"},
{"id":4,
    "name":"Access to learning videos"},
  {"id":5,
    "name":"Access to scheduled live chat"} 
];

const planB= [
    {"id":1,
    "name":"Six months plan"},
{"id":2,
    "name":"Access to chatroom"},
{"id":3,
    "name":"Access to test-drilling"},
{"id":4,
    "name":"Access to learning videos"},
  {"id":5,
    "name":"Access to scheduled live chat"} 
];

const planC= [
    {"id":1,
    "name":"Twelve months plan"},
{"id":2,
    "name":"Unlimited access"},    
{"id":3,
    "name":"Access to chatroom"},
{"id":4,
    "name":"Access to test-drilling"},
{"id":5,
    "name":"Access to learning videos"},
  {"id":6,
    "name":"Access to scheduled live chat"} 
]



export {planA,planB,planC}





