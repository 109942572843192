import { useGlobalContext } from "../../../controller/context_api";

const QuizTxt = (props) => {
  const questions = props.props.questions;
  const answer = questions?.answer;
  const q_id = props.props._id
  const id = props.props.index;
  const { QuizAnswer } = useGlobalContext();
  // console.log(id);
  const htmlQuestion = questions?.question
  return (
    <article className="test_quiz">
      <div
        className="question_text"
        dangerouslySetInnerHTML={{ __html: htmlQuestion }}
      />
      <div className="question_options">
        <form className="question_options_form">
          {questions?.options.map((option, index) => {
            return (
              <div className="single_options" key={index}>
                <input
                  type="radio"
                  id={index}
                  name="option"
                  value={option}
                  onClick={(e) => QuizAnswer({ e, answer, id, q_id })}
                />
                <label htmlFor="opp">{option}</label>
              </div>
            );
          })}
        </form>
      </div>
    </article>
  );
};

export default QuizTxt;
