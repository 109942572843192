import { useEffect, useState } from "react"
import { useGlobalContext } from "../../controller/context_api"
import Init from '../init_loading'
import axios2 from '../axios2'
import axios from '../axios'
import { useNavigate } from "react-router-dom"

const Payment = ()=>{
const{isload,setisload,loginfo, useremail,userfname, Updateuser}=useGlobalContext()
const history = useNavigate()

const queryParams = new URLSearchParams(window.location.search)
const status = queryParams.get("status")
const tx_ref = queryParams.get("tx_ref")
const transaction_id = queryParams.get("transaction_id")

useEffect( async()=> {
 try {
  setisload(true)
// settx_ref(queryParams.get("tx_ref"))
//let  headers = {authorization: `Bearer ${loginfo.token}`}
    await axios2.post('/payment',{
    p_status:status,
    tx_ref:tx_ref,
    transaction_id:transaction_id
}, 
//{headers:headers} 
).then(async(res)=>{ 
// console.log(res)
await axios.post('/updatenotification',{
username:`${res.data.email}`,
title:"Payment Notification",
description: `Congratulation ${res.data.first_name} this is to notify you that you have successfully subscribe for a ${res.data.plan} plan`
}).then(()=>{
setisload(false)
history(`/dashboard?message=${res.data.response.data.status}`)

}).catch((err)=>{
    // console.log(err)
})
})
.catch((err)=>{
    // console.log(err);
    setisload(false)
})
} catch (error) {
//    console.log(error) 
}

},[])

//console.log(status,tx_ref,transaction_id)

    return(

        <div className='secure_pay'>
         {isload&&<section className='log_loading'><Init/></section>}


        </div>
    )

}

export default Payment