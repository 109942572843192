import { Avatar, IconButton } from "@material-ui/core";
import { useEffect, useMemo, useRef, useState } from "react";
import { useGlobalContext } from "../../controller/context_api";
import ReplyIcon from "@material-ui/icons/Reply";
import SlowMotionVideoIcon from "@material-ui/icons/SlowMotionVideo";
import GraphicEqIcon from "@material-ui/icons/GraphicEq";

const SingleChat = (props) => {
  const {
    userfname,
    useremail,
    setChat_reply,
    chatMessage,
    setIs_reply,
    setChat_posi,
  } = useGlobalContext();

  const { _id, sender, time, message, user } = props.props;
  //   console.log("@****@", props.props, chatMessage);
  const newtime = time.slice(0, 10);
  //  console.log(newtime)
  const [issender, setIssender] = useState("");
  const is_reply = message[4].reply !== null;

  useMemo(() => {
    if (sender === useremail) {
      setIssender(true);
    }
  }, [useremail]);

  const last_chat = useRef(null);
  useEffect(() => {
    const lastchat = last_chat.current.getBoundingClientRect().bottom;
    setChat_posi(lastchat);
  }, [chatMessage]);

  const showReply = (e) => {
    e.currentTarget.children[0].children[0].classList.add("show_chat_reply");
  };
  const removeReply = (e) => {
    e.currentTarget.children[0].children[0].classList.remove("show_chat_reply");
  };

  const getReply = (props) => {
    const e = props.e;
    const m_id = props._id;
    const reply = chatMessage?.filter((msg) => msg._id == m_id);
    //console.log(reply[reply.length - 1]);
    setChat_reply(reply[0]);

    setIs_reply(true);
  };

  const playAudio = (e) => {
    // console.log(e.currentTarget.parentElement.children[0]);
    const aud = e.currentTarget.parentElement.children[0];
    const graph = e.currentTarget.children[0];
    const stero = e.currentTarget.children[1];
    console.log(aud);
    e.currentTarget.classList.toggle("ani_audio_btn");
    graph.classList.toggle("show_audio_icon");
    stero.classList.toggle("hide_audio_icon");

    if (aud.isPlaying == true) {
      aud.pause();
    } else {
      aud.play();
    }
  };

  return (
    <div
      className={`single_chat ${issender && "user_chat"}`}
      onMouseOver={showReply}
      onMouseOut={removeReply}
      ref={last_chat}
      key={_id}
    >
      <div className="chat_reply">
        <div className="show_chat">
          <IconButton onClick={(e) => getReply({ e, _id })}>
            <ReplyIcon />
          </IconButton>
        </div>
      </div>

      <article className="sender_info">
        <Avatar src="../../../graphics/avatar.ico" />
        <div className="sender_desc">
          <div className="sender_name">{user}~</div>
          <div className="sender_time">{newtime}</div>
        </div>
      </article>
      {is_reply && (
        <article className="sender_reply">
          <span className="reply_name">{message[4].reply?.user}~</span>
          <span className="reply_time">
            {message[4].reply.time?.slice(0, 10)}
          </span>
          <p className="reply_message">
            {/* {message[4].reply.message[1].message} */}
            {message[4].reply?.message[0].type == "audio" ? (
              <div className="message_audio">
                <div className="message_audio_btn">
                  <SlowMotionVideoIcon
                    style={{ fontSize: "25px" }}
                    className="audio_btn_icon"
                  />
                </div>
              </div>
            ) : (
              <div className="message_text">
                {message[4].reply.message[2].image !== null && (
                  <img src={message[4].reply?.message[2].image} />
                )}
                {message[4].reply?.message[1].message}
              </div>
            )}
          </p>
        </article>
      )}
      <article className="sender_message">
        {message[0].type == "audio" ? (
          <div className="message_audio">
            <audio>
              <source src={message[3].audio} type="audio/*" />
            </audio>
            <div className="message_audio_btn" onClick={playAudio}>
              <span className="audio_btn_icon hide_audio_icon">
                {" "}
                <GraphicEqIcon style={{ fontSize: "25px" }} />
              </span>
              <span className="audio_btn_icon ">
                <SlowMotionVideoIcon style={{ fontSize: "25px" }} />
              </span>
            </div>
          </div>
        ) : (
          <div className="message_text">
            {message[2].image !== null && <img src={message[2].image} />}
            {message[1].message}
          </div>
        )}
      </article>
    </div>
  );
};

export default SingleChat;
