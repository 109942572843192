import { Link } from "react-router-dom";
import { useGlobalContext } from "../../controller/context_api";
import Navigation from "../navigation";

const Hero = () => {
  // const windowHeight = window.innerHeight;
  const { isLogin } = useGlobalContext();
  return (
    <article
      style={{
        height: "100vh",
        background: "url('./graphics/background.png')",
        backgroundSize: "102% 100%",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "-5px",
      }}
      className="hero hero_resize"
    >
      {/* ............body of hero goes here.................................. */}
      <Navigation />
      {/* //////////////////////////////////////////////////////////////////////////////// */}
      <div className="hero_txt">
        <article className="hero_txt1">
          <h1 className="hero_h1"> Quick Solution to Jamb exam preparation </h1>
        </article>
        <article className="hero_txt2">
          <p className="hero_h2">
            {" "}
            Developed for science students, with over 300+ topics to learn from.{" "}
          </p>
        </article>
        <article className="hero_link">
          {isLogin ? (
            <Link to="/dashboard">
              {" "}
              <div className="hero_btn">Get Started</div>
            </Link>
          ) : (
            <Link to="/login">
              <div className="hero_btn">Get Started</div>
            </Link>
          )}
        </article>
      </div>
    </article>
  );
};

export default Hero;
