import { useGlobalContext } from "../../../controller/context_api";
import Overviewcard from "./overviewcard";
import { SlClose } from "react-icons/sl";
const TestOverview = () => {
  const { submitted, setSubmitted, questions } = useGlobalContext();
  console.log(questions);
  return (
    <section className="test_emergency">
      <div className="question_overview">
        <div className="question_summary">
          <h2>Question Summary</h2>{" "}
          <span className="ov_close" onClick={()=>setSubmitted(false)}>
            <SlClose className="ov_close_ico" />
          </span>
        </div>

        {questions?.map((question, index) => (
          <Overviewcard key={index} data={question} />
        ))}
      </div>
    </section>
  );
};

export default TestOverview;
