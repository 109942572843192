import {useEffect, useState} from 'react'
import Navigation from '../navigation'
import dashmenu from '../../model/dashmenu.json'
import DashboardMenu from './dashboard_menu'
import {Done, DoneAll, ExpandMore, FormatAlignLeftSharp} from '@material-ui/icons'
import { useGlobalContext } from '../../controller/context_api'
import { useNavigate } from 'react-router-dom'
import Loading from '../loading'
import { IconButton } from '@material-ui/core'
import axios from '../axios'
import Preloader from '../init_loading'
import Dashmenu from '../../views/mobile_dashnav'
import Dashdrop from '../../views/dashdrop'



const NotificationDashboard = ()=>{
const {islogin,isLoading,notification,useremail, Updateuser} = useGlobalContext()
const [isload, setIsload]=useState(false)
const history = useNavigate()

const showMore = async(props)=>{
const {e,_id}=props
  const element = e.currentTarget.parentElement.parentElement.parentElement.parentElement.parentElement.children[1].children[1]
  element.classList.toggle("show_msg_desc")
  
await axios.post("/updateread",{
  username:useremail,
  id:_id
}).then((res)=>{
  Updateuser()
}) 
}

const markRead =async()=>{
await axios.post("/markread",{
  username:useremail,
}).then((res)=>{
  Updateuser()
}) 
  
}

const [deletelist,setDeletelist] = useState([])
const markDelete =(props)=>{
const {e,_id}=props;
e.currentTarget.classList.toggle("active_notify_delete")
if(deletelist.includes(_id)){
  
  const others = deletelist.filter((del,index)=>del!== _id )
  
 setDeletelist(others)

}else{
  
  setDeletelist([...deletelist,_id])
}

}

const Deletenotify = async()=>{
  setIsload(true)
if(deletelist == []){
  setIsload(false) 
}else{

await axios.post("/deletenotify",{
  username:useremail,
  array:deletelist
}).then((res)=>{
  Updateuser()
  setDeletelist([])
  setIsload(false)
})

}

}





 return(
  <> 
{isLoading? <Loading/>:
<section className='dash_container'>
        <Navigation/>
<Dashmenu color={"rgb(1,1,26)"}/> 
<Dashdrop/>
        {/* -------------------- */}
{isload&&<section className='log_loading'><Preloader/></section>}
       {/* ////////////////////////////////////////////////////////////////// */}
        <article className='dash_body'>
            
        <DashboardMenu/>
        {/* /////////////////right content/////////////////////////////////// */}
        <div className='dash_l_content'>
        <article className='notify_header'>Notification</article>
        <article className='notify_controls'>
          <div className='notify_button'onClick={markRead}><span className='notify_symbol' ><DoneAll style={{fontSize: '14px', color: 'rgb(0,161,59)'}}/></span>Mark all as read</div>
          {/* <div className='notify_button'><span className='notify_symbol'><Done style={{fontSize:'14px', color: 'rgb(0,161,59)'}}/></span>Select all</div> */}
          <div className='notify_button danger' onClick={Deletenotify}>Delete</div>
        </article>
        {/* //////////////////notification body//////// */}
        <article className='notify_body'>
          
          {notification?.map((notify,index)=>{

const {_id} = notify

            return(
              <div className='single_notify_div' key={index}>
            {/* ////////////mark read and select element/ */}
            <article className='notify_actions'>
             <div className={`notify_read ${notify.isread&&"active_read"}`}></div> 
              <div className="notify_delete" onClick={(e)=>markDelete({e,_id})}></div>
            </article>
            {/* ///////////////message body///////////////////// */}
            
            <article className='notify_msg'>
              <div className='notify_msg_title'>{notify?.title}</div>
              <div className='notify_msg_desc'> {notify?.description}</div>
            </article>
            {/* ////////////date and view more/ view less element//// */}
          <article className='notify_date_ele'>
            <div className='notify_date'>{notify?.time}</div>
            <div className='notify_drop'><IconButton><ExpandMore onClick={(e)=>showMore({e,_id})}/></IconButton></div>
          </article>
          </div>
            )
          })}

        </article>  
        </div>
        </article>
        </section>
        }
        </>
    )
}

export default NotificationDashboard;