import {useEffect, useState, useMemo} from 'react'
import Navigation from '../navigation'
import dashmenu from '../../model/dashmenu.json'
import DashboardMenu from './dashboard_menu'
import { useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../controller/context_api'
import Loading from '../loading'
import DuoIcon from '@material-ui/icons/Duo';
import Dashmenu from "../../views/mobile_dashnav";
import Dashdrop from "../../views/dashdrop";


const LiveDashboard = ()=>{
const{islogin,isLoading,issubscribed} =useGlobalContext()
const history = useNavigate()

const [isauth, setIsauth] = useState(false);
useMemo(() => {
  if (issubscribed !== false) {
    setIsauth(true);
  }
}, [issubscribed]);

useEffect(() => {
  if (isauth) {
  } else {
    history("/dashboard", { replace: true });
  }
}, []);


 return(
  <> 
{isLoading? <Loading/>: 
<section className='dash_container'>
        <Navigation/>
        <Dashmenu color={"rgb(1,1,26)"}/>
        <Dashdrop/>
     {/* ////////////////////////////////////////////////////////////////// */}
        <article className='dash_body'>
            
        <DashboardMenu/>
        {/* /////////////////right content/////////////////////////////////// */}
        <div className='dash_l_content'>
        <div className="live_cont">
          <DuoIcon/>No upcoming webinar
        </div>
          
        </div>
        </article>
        </section>
        }
        </>
    )
}

export default LiveDashboard;