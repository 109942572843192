import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useGlobalContext } from "../controller/context_api";
import Preloader from "./preloader";

import Navigation from "./navigation";
import Homesection1 from "./home_section/homeSection1";
import Homesection2 from "./home_section/homeSection2";
import Homesection3 from "./home_section/homeSection3";
import Homesection4 from "./home_section/homeSection4";
import Homesection5 from "./home_section/homeSection5";
import Homesection6 from "./home_section/homeSection6";
import Hero1 from "./home_section/hero1";
import Hero2 from "./home_section/hero2";
import Footer from "./footer";
import { Helmet } from "react-helmet";

const Home = () => {
  const { isPreloader, setisPreloader, isLogin, isLoading } =
    useGlobalContext();

  //useEffect(()=>{ window.addEventListener('load',()=>{setisPreloader(false)})},[])

  return isLoading ? (
    <Preloader />
  ) : (
    <section className="home_content">
      {/* ------------------------------------------------------------------- */}
      <Helmet>
        <meta property="og:image" content="../../../graphics/home_og.png" />
        <meta
          name="description"
          content="Quick Solution to Jamb
      exam preparation | Developed for science students, with over 300+
      topics to learn from."
        />
        <meta
          name="twitter:card"
          content="Quick Solution to Jamb
      exam preparation | Developed for science students, with over 300+
      topics to learn from."
        />
        <meta
          name="keywords"
          content="jamb, computer based test, exam,cbt, jamb examination"
        />
        <meta property="og:title" content="Jaex Technology" />
        <meta
          property="og:description"
          content="Quick Solution to Jamb
      exam preparation | Developed for science students, with over 300+
      topics to learn from."
        />
        {/* <meta
            property="og:image"
            itemprop="image"
            content="https://firebasestorage.googleapis.com/v0/b/jaex-b9931.appspot.com/o/logo%2Fhome_og.png?alt=media&token=4842f49e-696f-4bfd-8bbe-4316d57dcc3a"
          /> */}
        <meta property="og:type" content="website" />

        <title>Jaex Technology</title>
      </Helmet>
      {/* ////////////////hero section/////////////////////////////////// */}
      <Hero1 />
      {/* --------------------------------------------- */}
      <Hero2 />
      {/* ///////////////////home section 1//////////////////////////////////////////// */}

      <Homesection1 />
      {/* ///////////////////home section 2/////////////////////////////////////// */}
      <Homesection2 />
      {/* ////////////////////home section 3 ///////////////////////////////////////// */}
      <Homesection3 />
      {/* ////////////////////home section 4//////////////////////////////////////////// */}
      {/* <Homesection4/> */}

      {/*/  /////////////////////home section 5///////////////////////////////////*/}

      <Homesection5 />
      <Homesection6 />

      <Footer />
    </section>
  );
};

export default Home;
