import React from "react";
import { Link } from "react-router-dom";
const homeSection6 = () => {
  return (
    <section className="download_section">
      <h2 className="download_head">Download App</h2>
      <p className="download_p">
        Experience full mobile learning flexibility with our app.
      </p>

      <img src="./graphics/login.png" alt="app" className="download_img" />

      <Link to="" className="download_btn">
        <img src="./graphics/play.png" alt="icon" />
        <span>
          <p>Get it on</p>
          <p>Google Play</p>
        </span>
      </Link>
    </section>
  );
};

export default homeSection6;
