import { useRef, useState } from "react";
import Navigation from "../navigation";
import dashmenu from "../../model/dashmenu.json";
import DashboardMenu from "./dashboard_menu";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../../controller/context_api";
import Loading from "../loading";
import DuoIcon from "@material-ui/icons/Duo";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import referrals from "../../model/referral.json";
import Dashmenu from "../../views/mobile_dashnav";
import Dashdrop from "../../views/dashdrop";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Init from "../init_loading";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { Close, OpenInBrowserRounded } from "@material-ui/icons";
import axios from '../axios'
import axios2 from '../axios2'


const ReferalDashboard = () => {
  const { islogin, isLoading, ref_id,useremail,balance,Updateuser,allusers} = useGlobalContext();
  const referral_link = `https://www.jaextechnology.com.ng/signup/?ref_id=${ref_id}`;

  const [iscopy, setIscopy] = useState(false);
  const ref_link = useRef(null);

  const copyRef = () => {
    const referral = referral_link;
    navigator.clipboard.writeText(referral);
    setIscopy(true);
    setTimeout(() => {
      setIscopy(false);
    }, 3000);
  };
const [amount,setAmount]=useState(0)
const [uban,setUban]=useState('')
const [bank,setBank]=useState('')
const [code,setCode]=useState('')
const [name,setName]=useState('')

const mynetwork = allusers.filter((users)=>users.referredby==ref_id)

const isnetwork = mynetwork
 console.log(isnetwork,mynetwork)

  const handleInputs = (e) => {

switch (e.currentTarget.name) {
  case "amount":setAmount(e.currentTarget.value)    
    break;
case "uban":setUban(e.currentTarget.value)    
    break;
case "bank":setBank(e.currentTarget.value)    
    break;  
case "code":setCode(e.currentTarget.value)    
    break;
case "name":setName(e.currentTarget.value)    
    break;
    
}

  };
const errsound = useRef(null)
const successsound = useRef(null)
const [iserr,setIserr]= useState(false)
const [err, seterr] = useState("");
const [issuccess,setIssuccess]= useState(false)
const [success,setSuccess]= useState('')
const [isload,setIsload]= useState(false)
const handleWithdrawal= async()=>{  
  const err_sound = errsound.current
  const success_sound = successsound.current
  // console.log(amount,name,uban,code,bank,useremail)
if(amount<1000){
  err_sound.play()
  setIserr(true);
  seterr("Enter a minimum amount of 1,000 NGN")
setTimeout(()=>{setIserr(false);
 seterr("")},3000)
}else if(amount>balance){
  err_sound.play()
  setIserr(true);
  seterr("Insufficient funds");
setTimeout(()=>{setIserr(false);
 seterr("")},3000)
}else{
setIsload(true)
await axios2.post("/withdrawal", {
  amount: amount,
  name: name,
  uban: uban,
  code: code,
  bank: bank,
  useremail: useremail,
}).then(async (res) => {
  // console.log(res);
  const message =res.data.msg 
  const referrence =res.data.transaction_id 
   await axios.post("/updatenotification", {
      username: `${res.data.email}`,
      title: "Withdrawal Notification",
      description: `A withdrawal request of ${amount}NGN with referrence:${referrence} has successfully been made. It will take 1-30 days for your transaction to be processed.`,
    }).then(()=>{
      Updateuser();
      setIsload(false);
      success_sound.play()
      setIssuccess(true)
   setSuccess(message)
  })    
}).catch((err) => {
      
  setIserr(true);
      seterr("Unable to process your request");
  ;
    });;
 
    setTimeout(() => {
      setIssuccess(false);
      setSuccess("");
      setIserr(false);
      seterr("");
    }, 3000);
}



}


  const history = useNavigate();
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <section className="dash_container">
          <Navigation />
          <Dashmenu color={"rgb(1,1,26)"} />
          <Dashdrop />
          {isload && (
            <section className="log_loading">
              <Init />
            </section>
          )}
          {/* ////////////////////////////////////////////////////////////////// */}
          <article className="dash_body">
            <DashboardMenu />
            {/* /////////////////right content/////////////////////////////////// */}
            <div className="dash_l_content">
              {/* --------------start------ */}
              <div className="ref_error">
                <audio
                  ref={errsound}
                  src="../../../../graphics/error.mp3"
                  style={{ visibility: "hidden" }}
                ></audio>
                <audio
                  ref={successsound}
                  src="../../../../graphics/success.mp3"
                  style={{ visibility: "hidden" }}
                ></audio>
                {issuccess && (
                  <div className="ref_error_item">
                    <CheckCircleIcon style={{ color: "rgb(15, 160, 5)" }} />
                    {success}
                  </div>
                )}

                {iserr && (
                  <div className="ref_error_item">
                    <Close style={{ color: "rgb(160, 41, 5)" }} />
                    {err}
                  </div>
                )}
              </div>
              {/*----------end----------  */}

              <section className="referral_body">
                <div className="referral_image_cont">
                  <div className="referral_image">
                    <img src="../../../../graphics/ref.png" alt="referral" />
                  </div>
                  <div className="referral_image_txt">
                    Refer a friend and make up to 100,000
                  </div>
                </div>
                {/* ---------------------------------- */}
                <div className="referral_title">Refer a friend</div>

                <div className="referral_desc1">
                  <p className="referral_desc" id="na">
                    and you will get 100 naira for each friend you invite
                  </p>
                  <div className="referral_link">
                    <span ref={ref_link}>{referral_link}</span>
                    <button onClick={copyRef}>
                      <FilterNoneIcon />
                    </button>
                    {iscopy && (
                      <div className="ref_link_modal">
                        <span>Link Copied to clipboard</span>
                      </div>
                    )}
                  </div>
                </div>
                {/* -------------------- */}

                <article className="referral_overview">
                  <div className="referral_title ref_title">How it work</div>

                  <div className="referral_desc2">
                    <div className="referral_desc_item">
                      {referrals.map((ref, index) => {
                        let color;
                        if (index == 0) {
                          color = "rgb(46, 186, 174)";
                        } else if (index == 1) {
                          color = "rgb(20, 123, 171)";
                        } else if (index == 1) {
                          color = "rgb(4, 48, 96)";
                        }

                        return (
                          <div
                            className="ref_item"
                            style={{ borderColor: color }}
                            key={index}
                          >
                            <div className="ref_num" style={{color: color }}>{ref.id}</div>
                            <div className="ref_item_desc">{ref.desc}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </article>
                {/* ------------------------------------------------------- */}
                <article className="referral_list_cont">
                  <div className="referral_title ref_title">My referrals</div>
                  <div className="referral_table_cont">
                    <table className="referral_table">
                      <th>
                        <tr className="ref_row1">User Id</tr>
                        <tr className="ref_row2">Status</tr>
                      </th>
                      {/* ------------------------------ */}
                      {/* ----------------------- */}
                      {mynetwork?.map((user)=>{ 

                        return(
                          <tbody>
                        <tr className="ref_row1">{user.email}</tr>
                        <tr className="ref_row2">
                          {user.ref_achieve?<CheckCircleIcon />:<span></span> 
                          }
                        </tr>
                      </tbody>
                        )
                      })}

                      {/* -------------------- */}
                    </table>
                  </div>
                </article>
                {/* ------------------------------------------------ */}

                <article className="referral_withdrawal">
                  <div className="referral_title">Withdrawal request</div>
                  <div className="referral_desc3">
                    <div className="ref_bal">
                      Balance: <span>{balance}</span> NGN
                    </div>
                    <div className="referral_form">
                      {/* ------------------------ */}
                      <div className="ref_form_div">
                        <input
                          type="number"
                          placeholder="0.00"
                          name="amount"
                          onChange={handleInputs}
                        />
                        <div className="ref_currency">NGN</div>
                      </div>
                      {/* ------------------------------------- */}
                      <div className="ref_form_div ref_uban">
                        <input
                          type="text"
                          name="uban"
                          placeholder="Account number"
                          maxLength={10}
                          onChange={handleInputs}
                        />
                      </div>

                      {/* ------------------------------------- */}
                      <div className="ref_form_div ref_bn">
                        <input
                          type="text"
                          name="bank"
                          placeholder="Bank name"
                          onChange={handleInputs}
                        />
                      </div>
                      {/* ------------------------------------- */}
                      <div className="ref_form_div">
                        <input
                          type="text"
                          name="code"
                          placeholder="Sort code"
                          onChange={handleInputs}
                        />
                      </div>
                      {/* ------------------------------------- */}
                      <div className="ref_form_div ref_an">
                        <input
                          type="text"
                          name="name"
                          placeholder="Account name"
                          onChange={handleInputs}
                        />
                      </div>

                      {/* ------------------------------------- */}
                    </div>
                    <div
                      className="referral_with_button"
                      onClick={handleWithdrawal}
                    >
                      Withdraw funds
                    </div>
                  </div>
                </article>
              </section>
            </div>
          </article>
        </section>
      )}
    </>
  );
};

export default ReferalDashboard;


// const dd =Date.parse('Sat Nov 19 2022 02:31:54 GMT+0100 (West Africa Standard Time)');

// const ds =Date.parse('Thu May 18 2023 00:49:40 GMT+0000 (Greenwich Mean Time');
// const is = dd > ds;