import { useState } from "react";
import { useGlobalContext } from "../../../controller/context_api";

const Modal = () => {
  const { ismodal, totalScore, StartDrill } = useGlobalContext();

  return (
    <article className="test-default"> 
      {/* <div className="test_static" style={{backgroundImage:`url("../../../../graphics/drill_stats.png")`, backgroundRepeat:"no-repeat", backgroundSize:"100% 100%"}}> 
    </div>  */}
      <div className="test_description">
        <div className="test_desc_item">
          <div className="test_d_s">
            <div className="test_d_txt">
              Welcome to Jeax test drill application, you will be given 1 hour
              50 minutes to attempt all questions. When you're done with your
              drill and your answers have been submited, you will find your
              score in this modal.
              <p>Goodluck!, enjoy your drill.</p>
            </div>

            <div className="test_d_btn" onClick={StartDrill}>
              {" "}
              Start drill
            </div>
          </div>

          <div className="test_s_s">
            <span className="test_s_score">Score</span>
            <span className="test_s_num">{totalScore}</span>
          </div>
        </div>
      </div>
    </article>
  );
};

export default Modal;
