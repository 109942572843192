import {useState} from "react"
import { AttachFile, CloudUploadOutlined, ExpandMore } from "@material-ui/icons"
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
 import { useGlobalContext } from "../../../controller/context_api"; 
import axios from "../../axios3";
const CmsUniversity = ()=>{

 const { setIsLoading, setisload } = useGlobalContext();
const [email, setEmail] = useState("User email");
const [amount, setAmount] = useState("Amount");
const [err, Seterr] = useState("");
const [iserr, Setiserr] = useState(false);
const [issuccess, setisSuccess] = useState(false);
const [success, setSuccess] = useState("");

const handleTextInput = (e) => {
    Setiserr(false);
    switch (e.currentTarget.name) {
      case "email":
        setEmail(e.currentTarget.value);
        break;
      case "amount":
        setAmount(e.currentTarget.value);
        break;
    }
  };

  const Getkeygen = async(e) => {
    e.preventDefault();
    try {
      if (
        email == "User email" ||
        amount == "Amount"  
      ) {
        Seterr("Please please all input fields");
        Setiserr(true);
      } else {
        setIsLoading(true);
        await axios
          .post(
            "/subscribe",
            {
              username: email,
              amount: amount,
            },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            setIsLoading(false);
            setisSuccess(true);
            setSuccess("User account successfully activated");
            setTimeout(() => {
              setEmail("User email");
              setAmount("Amount");
              setisSuccess(false);
              setSuccess("");
            }, 3000);
          })
          .catch((err) => {
            setIsLoading(false);
            Seterr("Fail to subscribe user");
            Setiserr(true);
            console.log(err);
          });}
    } catch (error) {
      console.log(error)
    }
    
  }

    return (
      <article className="cms_videoform_cont">
        <div className="cms_header">Keygen</div>
        <div className="err_div">
          {iserr && (
            <article className="error">
              <ErrorOutlineIcon /> {err}
            </article>
          )}
        </div>
        <div className="err_div">
          {issuccess && (
            <article className="success">
              <CheckCircleOutlineIcon /> {success}
            </article>
          )}
        </div>
        {/* ------------------------------------- */}
        <form className="cms_form">
          {/* ---------------------------------------------- */}
          

          <div className="cms_form_div">
            <input
              type="text"
              name="email"
              value={email}
              onChange={handleTextInput}
            />
          </div>
          {/* ------------------------------------------------------- */}
          <div className="cms_form_div">
            <input
              type="text"
              name="amount"
              value={amount}
              onChange={handleTextInput}
            />
          </div>
          {/* ------------------------------------------------------- */}
          
         

          {/* ------------------------------------------------------- */}
          <div className="cms_buttons" onClick={Getkeygen}>
            <ArrowRightAltIcon />
            Activate
          </div>
        </form>
      </article>
    );
}

export default CmsUniversity
