import { Link } from "react-router-dom";
import features from "./features.json";
import { useGlobalContext } from "../../controller/context_api";
import Wave from "react-wavify";
import { ArrowRightAlt } from "@material-ui/icons";

const Homesection3 = () => {
  const { isLogin } = useGlobalContext();

  return (
    <section className="sec3">
      <div className="wave">
        <Wave
          fill="rgba(137, 240, 171, 0.2)"
          paused={false}
          className="waves"
          style={{ display: "flex" }}
          options={{ height: 35, amplitude: 30, speed: 0.35, point: 3 }}
        />
        <Wave
          fill="rgba(196, 242, 243, 0.2)"
          paused={false}
          className="waves"
          style={{ display: "flex" }}
          options={{ height: 30, amplitude: 25, speed: 0.17, point: 4 }}
        />
        <Wave
          fill="rgb(255, 255, 255)" //rgba(61, 243, 122, 0.2)
          paused={false}
          className="waves"
          style={{ display: "flex" }}
          options={{ height: 60, amplitude: 20, speed: 0.31, point: 3 }}
        />
      </div>

      {features.map((item, index) => {
        const { id, highlight, desc, image, link } = item;
        return (
          <article
            className={`${
              index == 1 ? "features_cont f-row-reverse" : "features_cont"
            }`}
            key={id}
          >
            <div className="features_item1">
              <h2 className="features_heading">{highlight}</h2>
              <p className="features_desc">{desc}</p>
              {/* <article className='features_bar'></article> */}
              <div className="features_button">
                {isLogin ? (
                  <Link to={link} className="anchor-button">
                    explore more{" "}
                    <ArrowRightAlt
                      className="sec_icon"
                      style={{ fontSize: "20px" }}
                    />
                  </Link>
                ) : (
                  <Link to="/login" className="anchor-button">
                    explore more
                    <ArrowRightAlt
                      className="sec_icon"
                      style={{ fontSize: "20px" }}
                    />
                  </Link>
                )}
              </div>
            </div>

            {/* ////////////////////////////////// */}
            <div className="features_item2">
              <img src={image} alt="app-mockup" className="features_img" />
            </div>
          </article>
        );
      })}
    </section>
  );
};

export default Homesection3;
