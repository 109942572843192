import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Navigation from "./navigation";
import { useGlobalContext } from "../controller/context_api";
import logs from "../model/logs.json";
import Footer from "./footer";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import axios from "./axios";
import Loading from "./init_loading";
import Mobilenav from "./mobilenav";

const Reset = () => {
  const { setisLogin, setLoginfo, setIsLoading } = useGlobalContext();
  const history = useNavigate();
  const windowHeight = window.innerHeight;
  const [isloading, setisLoading] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  const [username, setUsername] = useState("");

  useEffect(async () => {
    await axios
      .post("/authentication", {
        token: token,
      })
      .then((res) => {
        // console.log(res)
        setUsername(res.data.email)
      })
      .catch((error) => {
        // console.log(error)
        const result = error.response.data.msg;
if(result == "notAllowed"){
    history("/404",{replace:true})
}

      });
  }, []);

  const [inputs, setInputs] = useState({
    password: "",
    confirm: "",
  });
  const [emailerr, setEmailerr] = useState("");
  const [passerr, setPasserr] = useState("");
  const [randerr, setRanderr] = useState("");
  const [isemailerr, setIsemailerr] = useState(false);
  const [ispasserr, setIspasserr] = useState(false);
  const [iserr, setIserr] = useState(false);
  const [issuccess, setIssuccess] = useState(false);
  // //////////////////////////////////////////////////////////
  const handleInput = (e) => {
    setInputs((prevstate) => ({
      ...prevstate,
      [e.target.name]: e.target.value,
    }));
    setIsemailerr(false);
    setIspasserr(false);
    setIserr(false);
    setIssuccess(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.password === "") {
      setIspasserr(true);
      setPasserr("please provide a password");
    } else if (inputs.confirm === "") {
      setIspasserr(true);
      setPasserr("please provide a confirm password");
    } else if (inputs.confirm !== inputs.password) {
      setIspasserr(true);
      setPasserr("password does not match");
    } else {
      setisLoading(true);
      setIserr(false);
      await axios
        .post("/reset", {
          password: inputs.confirm,
          username:username
        })
        .then((res) => {
          // console.log(res);
          setisLoading(false);
          setIssuccess(true);
          setRanderr(res.data.msg);
          setTimeout(()=>{
            history("/login",{replace:true})
          },3000)
        })
        .catch((error) => {
          // console.log(error);
          const result = error.response.data.msg;
          setisLoading(false);
          setRanderr(result);
          setIserr(true);
        });
    }
  };

  return (
    <section className="login_container" style={{ height: "100vh" }}>
      {/* ///////////////////navigation ////////////////////////////*/}
      <Navigation />
      <Mobilenav color={"rgb(1,1,26)"} />
      {/*////////////////////////////////////////////////////////////*/}
      {isloading && (
        <section className="log_loading">
          <Loading />
        </section>
      )}
      {/* ////////////////////////////////////////////////////// */}
      <article className="login_div">
        {/* //catching errors */}
        {iserr && (
          <div className="err_div">
            <article className="error">
              <ErrorOutlineIcon /> {randerr}
            </article>
          </div>
        )}
        {issuccess && (
          <div className="err_div">
            <article className="succ">{randerr}</article>
          </div>
        )}

        {/* ------------------------------- */}
        <div className="log_title">
          Change Password<span></span>
        </div>
        <form className="log_form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="password"
            id="password"
            placeholder="New password"
            onChange={handleInput}
            value={inputs.password}
          />
          <input
            type="text"
            name="confirm"
            id="confirm"
            placeholder="Confirm password"
            onChange={handleInput}
            value={inputs.confirm}
          />
          {ispasserr && (
            <span className="pass_err">
              <ErrorOutlineIcon />
              {passerr}
            </span>
          )}

          <button type="submit" onClick={handleSubmit}>
            Reset
          </button>
        </form>
        {/*  */}
      </article>

      <Footer />
    </section>
  );
};

export default Reset;



// link
// :
// "https://jaexinc.com/reset?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfZW1haWwiOiJqYWV4QG91dGxvb2suY29tIiwiZmlyc3RfbmFtZSI6ImphZXgiLCJsYXN0X25hbWUiOiJ0ZWNobm9sb2d5IiwiaWF0IjoxNjcyODU4MTk0LCJleHAiOjE2NzI5NDQ1OTR9.dovfQ5SFO8MsJ7xNEIwjGAziPf4P_q9fJxhhuczNb-E"
// msg
// :
// "A password recovery link has been sent to jaex@outlook.com"
