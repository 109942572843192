import {
  AttachFile,
  CloudUploadOutlined,
  ExpandMore,
} from "@material-ui/icons";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { useEffect, useRef, useState } from "react";
import courses from "../../../model/courses.json";
import axios from "../../axios3";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { useGlobalContext } from "../../../controller/context_api";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

const CmsQuestion = () => {
  const { setIsLoading, setisload } = useGlobalContext();

  const [course, setCourse] = useState("Course");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("Testdrill answer");
  const [year, setYear] = useState("2005");
  const [option1, setOption1] = useState("Option1");
  const [option2, setOption2] = useState("Option2");
  const [option3, setOption3] = useState("Option3");
  const [option4, setOption4] = useState("Option4");
  const [err, Seterr] = useState("");
  const [iserr, Setiserr] = useState(false);
  const [issuccess, setisSuccess] = useState(false);
  const [success, setSuccess] = useState("");
  const modules = {
    toolbar: [
      [{ headers: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ script: "sub" }, { script: "super" }],
    ],
  };
const examYear = [2005,2006,2007,2008,2009,2010, 2012,2013,2014,2015,2016,2017,2018,2019,2020, 2021, 2022, 2023,2024]
  useEffect(() => {
    setTimeout(() => {
      setQuestion("");
      setCourse("Course");
      setAnswer("Testdrill answer");
      setOption1("Option1");
      setOption2("Option2");
      setOption3("Option3");
      setOption4("Option4");
      setisSuccess(false);
      setSuccess("");
    }, 3000);

    return () => {
      clearTimeout();
    };
  }, [success]);

  const drop_cont_ref = useRef(null);
const drop_cont_ref2 = useRef(null);
  const handleOption = (e) => {
    const drop_cont = drop_cont_ref.current;
    drop_cont.classList.toggle("cms_show");
  };
 const handleOption2 = (e) => {
    const drop_cont = drop_cont_ref2.current;
    drop_cont.classList.toggle("cms_show");
  }; 
  const handleCourse = (e) => {
    setCourse(e.target.value);
    const drop_cont = drop_cont_ref.current;
    drop_cont.classList.remove("cms_show");
  };
const handleYear = (e) => {
    setYear(e.target.value);
    const drop_cont = drop_cont_ref2.current;
    drop_cont.classList.remove("cms_show");
  };
  const handleTextInput = (e) => {
    Setiserr(false);
    switch (e.currentTarget.name) {
      case "option1":
        setOption1(e.currentTarget.value);
        break;
      case "option2":
        setOption2(e.currentTarget.value);
        break;
      case "option3":
        setOption3(e.currentTarget.value);
        break;
      case "option4":
        setOption4(e.currentTarget.value);
        break;
      case "question":
        setQuestion(e.currentTarget.value);
        break;
      case "answer":
        setAnswer(e.currentTarget.value);
        break;
      case "course":
        setCourse(e.currentTarget.value);
    }
  };

  const Uploadquestion = async (e) => {
    e.preventDefault();

    const options = [option1, option2, option3, option4];
    const currentquestion = question;
    const currentanswer = answer;
    const currentcourse = course;

    try {
      if (
        options == [] ||
        currentquestion == "" ||
        currentanswer == "" ||
        currentcourse == ""
      ) {
        Seterr("Please please all input fields");
        Setiserr(true);
      } else {
        setIsLoading(true);
        await axios
          .post(
            "/question",
            {
              subject: currentcourse,
              question: currentquestion,
              answer: currentanswer,
              options: options,
              year:year,
            },
            {
              withCredentials: true,
            }
          )
          .then((res) => {
            setIsLoading(false);
            setisSuccess(true);
            setSuccess("New question uploaded successfully");
            setTimeout(() => {
              setQuestion("");
              setCourse("Course");
              setAnswer("Testdrill answer");
              setOption1("Option1");
              setOption2("Option2");
              setOption3("Option3");
              setOption4("Option4");
              setisSuccess(false);
              setSuccess("");
            }, 3000);
          })
          .catch((err) => {
            setIsLoading(false);
            console.log(err);
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <article className="cms_videoform_cont">
      <div className="cms_header">Question</div>
      {/* ---------------error------------------------- */}
      <div className="err_div">
        {iserr && (
          <article className="error">
            <ErrorOutlineIcon /> {err}
          </article>
        )}
      </div>
      <div className="err_div">
        {issuccess && (
          <article className="success">
            <CheckCircleOutlineIcon /> {success}
          </article>
        )}
      </div>
      {/* ------------------------------------- */}
      <form className="cms_form">
        {/* ---------------------------------------------- */}
        <div className="cms_form_div">
          <div className="cms_form_content">{course}</div>
          <div className="cms_drop" onClick={handleOption}>
            <ExpandMore />
          </div>
          <div className="cms_drop_content" ref={drop_cont_ref}>
            <article className="cms_drop_form">
              {courses.map((course, index) => {
                const { id, name } = course;

                return (
                  <div className="cms_drop_form_div" key={id}>
                    <input
                      type="radio"
                      name="courses"
                      id={name}
                      value={name}
                      onClick={handleCourse}
                    />
                    <label htmlFor={name}>{name}</label>
                  </div>
                );
              })}
            </article>
          </div>
        </div>
        {/* ---------------------------------------------- */}
        <div className="cms_form_div">
          <div className="cms_form_content">{year}</div>
          <div className="cms_drop" onClick={handleOption2}>
            <ExpandMore />
          </div>
          <div className="cms_drop_content" ref={drop_cont_ref2}>
            <article className="cms_drop_form">
              {examYear.map((yr, index) => {            

                return (
                  <div className="cms_drop_form_div" key={index}>
                    <input
                      type="radio"
                      name="courses"
                      id={yr}
                      value={yr}
                      onClick={handleYear}
                    />
                    <label htmlFor={yr}>{yr}</label>
                  </div>
                );
              })}
            </article>
          </div>
        </div>
        {/* ---------------------------------------------- */}

        <div className="cms_form_div">
          {/* <textarea
            name="question"
            onChange={handleTextInput}
            placeholder="Description"
            className="single_note"
          ></textarea> */}

          <ReactQuill
            theme="snow"
            //value={question}
            placeholder="Question"
            onChange={setQuestion}
            className="single_note"
            modules={modules}
          />
        </div>
        {/* ------------------------------------------------------- */}
        <div className="cms_form_div">
          <input
            type="text"
            name="answer"
            value={answer}
            onChange={handleTextInput}
          />
        </div>
        {/* ------------------------------------------------------- */}
        <div className="cms_form_div">
          <input
            type="text"
            name="option1"
            value={option1}
            onChange={handleTextInput}
          />
        </div>
        {/* ------------------------------------------------------- */}
        <div className="cms_form_div">
          <input
            type="text"
            name="option2"
            value={option2}
            onChange={handleTextInput}
          />
        </div>
        {/* ------------------------------------------------------- */}
        <div className="cms_form_div">
          <input
            type="text"
            name="option3"
            value={option3}
            onChange={handleTextInput}
          />
        </div>
        {/* ------------------------------------------------------- */}
        <div className="cms_form_div">
          <input
            type="text"
            name="option4"
            value={option4}
            onChange={handleTextInput}
          />
        </div>
        {/* ------------------------------------------------------- */}

        {/* ------------------------------------------------------- */}
        <div className="cms_buttons" onClick={Uploadquestion}>
          <ArrowRightAltIcon />
          Upload
        </div>
      </form>
    </article>
  );
};

export default CmsQuestion;
