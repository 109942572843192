

import React from 'react'
import Navigation from './navigation'
import Footer from './footer'
import privacy from '../model/privacy.json'
import data from '../model/privacy_data.json'
import Mobilenav from './mobilenav'
import {Helmet} from "react-helmet"

const Terms = () => {
  return (
    <section className="company_cont">
      <Helmet>
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/jaex-b9931.appspot.com/o/logo%2Fhome_og.png?alt=media&token=4842f49e-696f-4bfd-8bbe-4316d57dcc3a"
        />
        <meta
          name="description"
          content="Jaex’s mission is to improve lives through learning. We enable anyone
          anywhere to create and share educational content (instructors) and to
          access that educational content to learn (students)."
        />
        <meta
          name="twitter:card"
          content="Jaex’s mission is to improve lives through learning. We enable anyone
          anywhere to create and share educational content (instructors) and to
          access that educational content to learn (students)."
        />
        <meta
          name="keywords"
          content="jamb, computer based test, exam,cbt, jamb examination"
        />
        <meta property="og:title" content="Terms of use" />
        <meta
          property="og:description"
          content="Jaex’s mission is to improve lives through learning. We enable anyone
          anywhere to create and share educational content (instructors) and to
          access that educational content to learn (students)."
        />
        <meta
          property="og:image"
          itemprop="image"
          content="https://firebasestorage.googleapis.com/v0/b/jaex-b9931.appspot.com/o/logo%2Fhome_og.png?alt=media&token=4842f49e-696f-4bfd-8bbe-4316d57dcc3a"
        />
        <meta property="og:type" content="website" />

        <title>Terms of use</title> 
      </Helmet>
      <Navigation />
      <Mobilenav color={"rgb(1,1,26)"} />
      <article className="privacy_body">
        <h1>Terms of Use</h1>
        <p>These Terms of Use ("Terms") were last updated on Nov 03, 2022.</p>
        <p>
          Jaex’s mission is to improve lives through learning. We enable anyone
          anywhere to create and share educational content (instructors) and to
          access that educational content to learn (students). We consider our
          marketplace model the best way to offer valuable educational content
          to our users. We need rules to keep our platform and services safe for
          you, us, and our student and instructor community. These Terms apply
          to all your activities on the Jaex website, the Jaex mobile
          applications, our TV applications, our APIs, and other related
          services (“Services”).
        </p>

        {/* ------------------------------------- */}
        <h1>Table of Contents</h1>
        <ul className="data_list">
          <li>1. Accounts</li>
          <li>2. Payments, Credits, and Refunds</li>
          <li>3. Jaex’s Rights</li>
          <li>4. Subscription Terms</li>
          <li>5. Miscellaneous Legal Terms</li>
          <li>6. Updating These Terms</li>
        </ul>

        {/* --------------------------------------------- */}
        <h1>1. Accounts</h1>
        <p>
          You need an account for most activities on our platform. Keep your
          password somewhere safe, because you’re responsible for all activity
          associated with your account. You must have reached the age of consent
          for online services in your country to use Jaex.
        </p>
        <p>
          You need an account for most activities on our platform, including to
          subscribe and access contents. When setting up and maintaining your
          account, you must provide and continue to provide accurate and
          complete information, including a valid email address. You have
          complete responsibility for your account and everything that happens
          on your account, including for any harm or damage (to us or anyone
          else) caused by someone using your account without your permission.
          This means you need to be careful with your password. You may not
          transfer your account to someone else or use someone else’s account.
          If you contact us to request access to an account, we will not grant
          you such access unless you can provide us with the information that we
          need to prove you are the owner of that account. In the event of the
          death of a user, the account of that user will be closed.
        </p>
        <p>
          You may not share your account login credentials with anyone else. You
          are responsible for what happens with your account and Jaex will not
          intervene in disputes between students who have shared account login
          credentials. You must notify us immediately upon learning that someone
          else may be using your account without your permission. We may request
          some information from you to confirm that you are indeed the owner of
          your account.
        </p>
        <p>
          Students must be at least 16 years of age to create an account on Jaex
          and use the Services.
        </p>

        {/* ----------------------------------- */}

        <h1>2. Payments, Credits, and Refunds</h1>
        <p>
          When you make a payment, you agree to use a valid payment method. If
          you aren’t happy with your content, Jaex offers a 30-day refund or
          credit for most content purchases.
        </p>
        <h2>2.1 Pricing</h2>
        <p>
          The prices of our services on Jaex are determined based on the number
          of period for which you are subscribing for. Our subscription plan for
          the period of Three months is 2,100 naira, plan for Six months is
          3,600 naira while that for Twelve months is 6,000 naira.
        </p>

        <p>
          We occasionally run promotions and sales for our content, during which
          certain content is available at discounted prices for a set period of
          time. The price applicable to the content will be the price at the
          time you complete your purchase of the content (at checkout). If you
          are logged into your account, the listed currency you see is based on
          your location when you created your account. If you are not logged
          into your account, the price currency is based on the country where
          you are located. We do not enable users to see pricing in other
          currencies.
        </p>
        {/* ------------------------------------------- */}

        <h1>3. Jaex’s Rights</h1>
        <p>
          We own the Jaex platform and Services, including the website, present
          or future apps and services, and things like our logos, API, code, and
          content created by our employees. You can’t tamper with those or use
          them without authorization.
        </p>
        <p>
          All right, title, and interest in and to the Jaex platform and
          Services, including our website, our existing or future applications,
          our APIs, databases, and the content our employees or partners submit
          or provide through our Services (but excluding content provided by
          instructors and students) are and will remain the exclusive property
          of Jaex and its licensors. Our platforms and services are protected by
          copyright, trademark. Nothing gives you a right to use the Jaex name
          or any of the Jaex trademarks, logos, domain names, and other
          distinctive brand features. Any feedback, comments, or suggestions you
          may provide regarding Jaex or the Services is entirely voluntary and
          we will be free to use such feedback, comments, or suggestions as we
          see fit and without any obligation to you.
        </p>

        {/* ------------------------------- */}

        <h1>4. Subscription Terms</h1>
        <p>
          This section covers additional terms that apply to your use of our
          subscription-based collections as a student (“Subscription Plans”). By
          using a Subscription Plan, you agree to the additional terms in this
          section. Note that use of Jaex Business is not subject to these Terms,
          but is instead governed by the agreement between Jaex and the
          subscribing organization.
        </p>

        <h2>4.1 Subscription Plans</h2>
        <p>
          During your subscription to a Subscription Plan, you get a limited,
          non-exclusive, non-transferable license from us to access and view the
          content included in that Subscription Plan via the Services. Your
          Subscription Plan may also include access to interactive environments,
          such as chatroom(“Interactive Sessions”).
        </p>
        <p>
          The subscription that you purchase or renew determines the scope,
          features, and price of your access to a Subscription Plan. You may not
          transfer, assign, or share your subscription with anyone else. We
          reserve the right to revoke any license to use the content in our
          Subscription Plans for legal or policy reasons at any time and at our
          sole discretion, such as if we no longer have the right to offer the
          content through a Subscription Plan.{" "}
        </p>

        <h2>4.2 Account Management</h2>
        <p>
          You may cancel your subscription by not renewing your Subscription
          Plan, your access to that Subscription Plan will automatically end on
          the last day of your billing period. Not renewing a subscription does
          not terminate your Jaex account.
        </p>

        <h2>4.3 Payments and Billing</h2>
        <p>
          The subscription fee will be listed at the time of your purchase. We
          may also be required to add taxes to your subscription fee as
          described in the “Payments, Credits, and Refunds” section above.
          Payments are non-refundable and there are no refunds or credits for
          partially used periods, unless otherwise required by applicable law.
          To subscribe to a Subscription Plan, you must provide a payment
          method. By subscribing to a Subscription Plan and providing your
          billing information during checkout, you grant us and our payment
          service providers the right to process payment for the then-applicable
          fees via the payment method we have on record for you. At the end of
          each subscription term, we will automatically renew your subscription
          for the same length of term and process your payment method for
          payment of the then-applicable fees. We reserve the right to change
          our Subscription Plans or adjust pricing for our Services at our sole
          discretion. Any price changes or changes to your subscription will
          take effect following notice to you, except as otherwise required by
          applicable law.
        </p>

        <h2>4.4 Interactive Session Restrictions</h2>
        <p>
          You may not do any of the following while accessing or using the
          Interactive Sessions:
        </p>
        <ul>
          <li>
            use the Interactive Sessions for any purpose other than for studying
            purposes
          </li>
          <li>
            provide web, database, or forum access, or engage in cryptocurrency
            mining, on or through the Interactive Sessions;
          </li>
          <li>
            access or use the Interactive Sessions in any commercial production
            environment;
          </li>
          <li>
            take any action in the Interactive Sessions that results in a
            disruption or interference of our Services or the stability of our
            infrastructure; or These restrictions are in addition to those
            listed elsewhere in these Terms, including in the “Content and
            Behavior Rules” and “Jaex’s Rights” sections above.
          </li>
        </ul>

        {/* ----------------------------------------- */}
        <h1>5. Miscellaneous Legal Terms</h1>
        <p>
          These Terms are like any other contract, and they have boring but
          important legal terms that protect us from the countless things that
          could happen and that clarify the legal relationship between us and
          you.
        </p>

        <h2>5.1 Binding Agreement</h2>
        <p>
          You agree that by registering, accessing, or using our Services, you
          are agreeing to enter into a legally binding contract with Jaex. If
          you do not agree to these Terms, do not register, access, or otherwise
          use any of our Services.
        </p>
        <p>
          If any part of these Terms is found to be invalid or unenforceable by
          applicable law, then that provision will be deemed superseded by a
          valid, enforceable provision that most closely matches the intent of
          the original provision and the remainder of these Terms will continue
          in effect.
        </p>

        <p>
          Even if we are delayed in exercising our rights or fail to exercise a
          right in one case, it doesn’t mean we waive our rights under these
          Terms, and we may decide to enforce them in the future. If we decide
          to waive any of our rights in a particular instance, it doesn’t mean
          we waive our rights generally or in the future.
        </p>

        <h2>5.2 Disclaimers</h2>
        <p>
          It may happen that our platform is down, either for planned
          maintenance or because something goes down with the site. It may
          happen that one of our instructors is making misleading statements in
          their content. It may also happen that we encounter security issues.
          These are just examples. You accept that you will not have any
          recourse against us in any of these types of cases where things don’t
          work out right. We may decide to cease making available certain
          features of the Services at any time and for any reason. Under no
          circumstances will Jaex or its affiliates, suppliers, partners or
          agents be held liable for any damages due to such interruptions or
          lack of availability of such features.
        </p>
        <p>
          We are not responsible for delay or failure of our performance of any
          of the Services caused by events beyond our reasonable control, like
          an act of war, hostility, or sabotage; natural disaster; electrical,
          internet, or telecommunication outage; or government restrictions.
        </p>

        <h2>5.3 Relationship Between Us</h2>
        <p>
          You and we agree that no joint venture, partnership, employment,
          contractor, or agency relationship exists between us.
        </p>

        {/* -------------------------------------------------------- */}
        <h1>6. Updating These Terms</h1>
        <p>
          From time to time, we may update these Terms to clarify our practices
          or to reflect new or different practices (such as when we add new
          features), and Jaex reserves the right in its sole discretion to
          modify and/or make changes to these Terms at any time. If we make any
          material change, we will notify you using prominent means, such as by
          email notice sent to the email address specified in your account or by
          posting a notice through our Services. Modifications will become
          effective on the day they are posted unless stated otherwise. Your
          continued use of our Services after changes become effective shall
          mean that you accept those changes. Any revised Terms shall supersede
          all previous Terms.
        </p>
      </article>

      <Footer />
    </section>
  );
}

export default Terms