import { useGlobalContext } from "../../controller/context_api"

import CMSvideo from './cmsform/cmsvideo'

const Video = ()=>{
const {adminposition} = useGlobalContext()

    return(
        <article className="admin_form_component" style={{right:"0%", transform:`translateX(-${adminposition}%)`}}>
            <CMSvideo/>
        </article>
    )
}
export default Video 