import { useGlobalContext } from "../../../controller/context_api"


 
const Emergency =()=>{
const {isemergency, SubmitDrill}= useGlobalContext()

return(
    <section className="test_emergency">
<div className="test_start emergency_button" onClick={SubmitDrill}>Finish/Submit</div>
    </section>
)
}

export default Emergency