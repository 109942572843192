import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from "@material-ui/core";
import {
  ArrowBack,
  ArrowBackIos,
  QuestionAnswer,
  VideoLibrary,
  LibraryBooks,
} from "@material-ui/icons";
import DuoIcon from "@material-ui/icons/Duo";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import SchoolIcon from "@material-ui/icons/School";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

import { Link } from "react-router-dom";
import { useGlobalContext } from "../../controller/context_api";
import Adminpages from "./admin.json";

const Nav =(props)=>{

const {isopen,setisopen,closeMenu} = props.props
// console.log(isopen)
const { Setadminposition } = useGlobalContext();

const changeMenu = (e) => {
    setisopen(false)
  const id = e.currentTarget.dataset.id;
  if (id == 1) {
    Setadminposition(0);
  } else if (id == 2) {
    Setadminposition(100);
  } else if (id == 3) {
    Setadminposition(200);
  } else if (id == 4) {
    Setadminposition(300);
  } else if (id == 5) {
    Setadminposition(400);
  } else if (id == 6) {
    Setadminposition(500);
  } else if (id == 7) {
    Setadminposition(600);
  }
};

    return (
      <article
        className={
          isopen ? "mobile_admin_nav show_mobile_admin_nav" : "mobile_admin_nav"
        }
      >
        <div className="mobile_admin_nav_ctr">
          <IconButton className="h_menu" onClick={closeMenu}>
            <CloseIcon />
          </IconButton>
        </div>

        <div className="mobile_admin_nav_cont">
          <article className="admin_menu_container">
            {Adminpages.map((pages, index) => {
              return (
                <div
                  className="admin_menu_item"
                  key={index}
                  onClick={changeMenu}
                  
                  data-id={index + 1}
                >
                  {(index == 0 && <DuoIcon />) ||
                    (index == 1 && <EventAvailableIcon />) ||
                    (index == 2 && <LibraryBooks />) ||
                    (index == 3 && <SupervisorAccountIcon />) ||
                    (index == 4 && <LocalLibraryIcon />) ||
                    (index == 5 && <BeenhereIcon />) ||
                    (index == 6 && <SchoolIcon />)}
                  {pages.name}
                </div>
              );
            })}
          </article>
          <Link to="/" className="admin_menu_button_a">
            <article className="admin_menu_button">
              <ArrowBackIos /> Back home
            </article>
          </Link>
        </div>
      </article>
    );
}

export default Nav