import {useEffect, useState} from 'react'
import Navigation from '../navigation'
import dashmenu from '../../model/dashmenu.json'
import DashboardMenu from './dashboard_menu'
import { Link, useNavigate } from 'react-router-dom'
import { useGlobalContext } from '../../controller/context_api'
import Loading from '../loading'
import PlanA from './subplans/plan_a'
import PlanB from './subplans/plan_b'
import PlanC from './subplans/plan_c'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Dashmenu from '../../views/mobile_dashnav'
import Dashdrop from '../../views/dashdrop'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import plans from '../../model/plan_info.json'

const Dashboard = ()=>{
const{islogin,isLoading, Updateuser} =useGlobalContext()
const history = useNavigate()

const queryParams = new URLSearchParams(window.location.search)
const message = queryParams.get("message")
//console.log(message,"hello")

const [iserror,setisError] = useState(false)
const [issuccess,setisSuccess] = useState(false)
const [error,setError] = useState('')
const [success,setSuccess] = useState('')
  
useEffect(()=>{
  if(message === 'successful'){setisSuccess(true);setSuccess('Payment was made successfully') }else
  if(message === 'unsuccessful'){setisError(true);setError('Payment was not successful') }
Updateuser()
setTimeout(()=>{
setisSuccess(false);setSuccess('')  
setisError(false);setError('')
},9000)

return ()=>clearTimeout()


},[]) 



 return(
  <> 
{isLoading? <Loading/>:
<section className='dash_container'>
        <Navigation/>
        <Dashmenu color={"rgb(1,1,26)"}/>
        <Dashdrop/>
     {/* ////////////////////////////////////////////////////////////////// */}
        <article className='dash_body'>
            
        <DashboardMenu/>
        {/* /////////////////right content/////////////////////////////////// */}
        <div className='dash_l_content' style={{overflow:'hidden'}}>

          {/* ------------------------- */}
          {iserror&&<div className='err_div'><article className='error error2'><ErrorOutlineIcon/> {error}</article></div>}
            {issuccess&&<div className='err_div'><article className='success'><CheckCircleOutlineIcon/> {success}</article></div>}
            {/* /////////////////////////// */}
        <article className='dashone'>
        <div className='dashone_desc'> Get Started by choosing a subscription plan that
        best suits your budget.</div>
        </article>
        <article  style={{backgroundImage:`url("")`,backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}} className='dash_plan_cont'>

        <PlanA/>
<PlanC/>
      <PlanB/>  
        </article>

        {/* ---------------------------------- */}
<article className="mobile_dash_plan">
<article className="mobile_dash_plan_cont">
  
{plans.map((plan,index)=>{
let color;
if(index ==0){color = 'rgb(247, 117, 3)'}else if(index ==1){color = 'rgb(136, 191, 7)'}else if(index ==2){color = 'rgb(2, 4, 35)'}

return(
  <article className='mobile_plan_body' >
            <div className='plan_tag' style={{backgroundColor:`${color}`}}>{plan.plan}</div>
            <div className='plan_value'><AccountBalanceWalletIcon/>{plan.amount}</div>
            <article className='plan_desc_cont'>
            {plan.desc.map((plan,index)=>{
            const {name}=plan;
            return(<div className='plan_desc' key={index}><CheckCircleOutlineIcon/>{name}</div>)})}
            </article>
 <Link to={plan.link} className='plan_checkout' >Get started</Link>
        </article>
)
})}


</article>
</article>


          
        </div>
        </article>
        </section>
        }
        </>
    )
}

export default Dashboard;