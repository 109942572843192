import Book from "./book";
import Quiz from "./quiz";
import Cutoff from "./cutoff";
import Question from "./question";
import Testimony from "./testimony";
import Subscription from "./subscription";
import Video from "./video";

const AdminBody = () => {
  return (
    <section className="admin_container">
      <article className="admin_heading">Content Management System </article>
      <article className="admin_container_content">
        <Video />
        <Question />
        <Book />
        <Testimony />
        <Quiz />
        <Cutoff />
        <Subscription />
      </article>
    </section>
  );
};

export default AdminBody;
