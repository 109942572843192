import { useGlobalContext } from "../../controller/context_api";

const ChangeQuestion = () => {
  const {
    changeQuestion,
    counter,
    SubmitDrill,
    isend,
    isprevious,
    previousQuestion,
  } = useGlobalContext();

  return (
    <article className="test_question_control">
      {counter > 0 && (
        <div className="previous_questions" onClick={previousQuestion}>
          Back
        </div>
      )}
      {counter >= 99 ? (
        <div className="next_questions" onClick={SubmitDrill}>
          Finish/submit
        </div>
      ) : (
        <div className="next_questions" onClick={changeQuestion}>
          Next
        </div>
      )}
    </article>
  );
};

export default ChangeQuestion;
