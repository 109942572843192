import { IconButton } from "@material-ui/core";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import { useRef, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import pages from "../model/pages.json";
import { Link, useNavigate } from "react-router-dom";

import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import EventNoteOutlinedIcon from "@material-ui/icons/EventNoteOutlined";
import AccountBalanceOutlinedIcon from "@material-ui/icons/AccountBalanceOutlined";
import ExploreOutlinedIcon from "@material-ui/icons/ExploreOutlined";
import { useGlobalContext } from "../controller/context_api";
import axios from "./axios";

const Mobile = (props) => {
  const color = props.color;

  const history = useNavigate();
  const mobile = useRef(null);
  const [isopen, setIsopen] = useState(false);
  const { isLogin, setIsLoading, setisLogin } = useGlobalContext();
  const Navi = () => {
    isLogin ? history("/dashboard") : history("/login");
  };

  const showMenu = () => {
    const menu = mobile.current;
    setIsopen(!isopen);
    menu.classList.toggle("show_mobile_menu");
  };

  const handleLogout = async () => {
    setIsLoading(true);
    await axios.get("/logout", { withCredentials: true }).then((res) => {
      setisLogin(false);
      setIsLoading(false);
      history("/login", { replace: true });
    });
  };
  return (
    <nav className="mobile_nav" style={{ backgroundColor: `${color}` }}>
      <IconButton className="h_menu" onClick={showMenu}>
        {isopen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
      <div className="h_logo_div">
        {isopen ? (
          <img src="../../../../graphics/logo2c.ico" alt="logo" />
        ) : (
          <img src="../../../../graphics/logo1.ico" alt="logo" />
        )}
      </div>
      <IconButton className="h_menu_icon">
        <SearchIcon />
      </IconButton>
      <IconButton className="h_menu_icon" onClick={Navi}>
        <AccountCircleOutlinedIcon />
      </IconButton>

      <div className="mobile_drop_menu" ref={mobile}>
        <div className="drop_menu_cont">
          {pages.map((page, index) => {
            return (
              <Link to={page.route} key={index}>
                {(index == 0 && <HomeOutlinedIcon />) ||
                  (index == 1 && <EventNoteOutlinedIcon />) ||
                  (index == 2 && <AccountBalanceOutlinedIcon />) ||
                  (index == 3 && <ExploreOutlinedIcon />)}
                {page.name}
              </Link>
            );
          })}
        </div>
        {/* ---------------------------- */}
        <div className="drop_menu_button">
          {isLogin ? (
            <div className="drop_menu_btn" onClick={handleLogout}>
              Logout
            </div>
          ) : (
            <Link to="/login" className="drop_menu_btn">
              Login
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Mobile;
