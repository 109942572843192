

const Loading = ()=>{
    return(
        <article className="log_ani">
        <span className='log_dots' id='log_1'></span>  
        <span className='log_dots' id='log_2'></span> 
        <span className='log_dots' id='log_3'></span>  
        </article>
    )
}

export default Loading;