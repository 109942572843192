import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Navigation from "./navigation";
import { useGlobalContext } from "../controller/context_api";
import logs from "../model/logs.json";
import Footer from "./footer";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import axios from "./axios";
import Loading from "./init_loading";
import Mobilenav from "./mobilenav";

const Recovery = () => {
  const { setisLogin, setLoginfo, setIsLoading } = useGlobalContext();
  const history = useNavigate();
  const windowHeight = window.innerHeight;
  const [isloading, setisLoading] = useState(false);
  //useEffect(()=>{
  const changeLog = (e) => {
    e.currentTarget.classList.add("change_log_btn");
    //console.log(e.currentTarget,'clicked')
  };

  const rmchangeLog = (e) => {
    e.currentTarget.classList.remove("change_log_btn");
    //console.log(e.currentTarget,'clicked')
  };
  //},[])
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [emailerr, setEmailerr] = useState("");
  const [passerr, setPasserr] = useState("");
  const [randerr, setRanderr] = useState("");
  const [isemailerr, setIsemailerr] = useState(false);
  const [ispasserr, setIspasserr] = useState(false);
  const [iserr, setIserr] = useState(false);
  const [issuccess, setIssuccess] = useState(false);
  // //////////////////////////////////////////////////////////
  const handleInput = (e) => {
    setInputs((prevstate) => ({
      ...prevstate,
      [e.target.name]: e.target.value,
    }));
    setIsemailerr(false);
    setIspasserr(false);
    setIserr(false);
    setIssuccess(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.email === "") {
      setIsemailerr(true);
      setEmailerr("please provide a valid email");
    } else {
      setisLoading(true);
      setIserr(false);
      await axios
        .post("/recovery", {
          username: inputs.email.toLowerCase().trim(),
        })
        .then((res) => {
          console.log(res);
          setisLoading(false);
          setIssuccess(true);
          setRanderr(res.data.msg);
        })
        .catch((error) => {
          console.log(error);
          const result = error.response.data;
          setisLoading(false);
          setRanderr(result);
          setIserr(true);
        });
    }
  };

  return (
    <section className="login_container" style={{ height: "100vh" }}>
      {/* ///////////////////navigation ////////////////////////////*/}
      <Navigation />
      <Mobilenav color={"rgb(1,1,26)"} />
      {/*////////////////////////////////////////////////////////////*/}
      {isloading && (
        <section className="log_loading">
          <Loading />
        </section>
      )}
      {/* ////////////////////////////////////////////////////// */}
      <article className="login_div">
        {/* //catching errors */}
        {iserr && (
          <div className="err_div">
            <article className="error">
              <ErrorOutlineIcon /> {randerr}
            </article>
          </div>
        )}
        {issuccess && (
          <div className="err_div">
            <article className="succ">{randerr}</article>
          </div>
        )}

        {/* ------------------------------- */}
        <div className="log_title">
          Password Recovery <span></span>
        </div>
        <form className="log_form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="Enter your registered email"
            onChange={handleInput}
            value={inputs.email}
          />
          {isemailerr && (
            <span className="pass_err">
              <ErrorOutlineIcon />
              {emailerr}
            </span>
          )}

          <button type="submit" onClick={handleSubmit}>
            Reset
          </button>
        </form>
        {/*  */}
      </article>

      <Footer />
    </section>
  );
};

export default Recovery;
