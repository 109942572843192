import { useEffect, useRef, useState, useMemo } from "react";
import Navigation from "../navigation";
import dashmenu from "../../model/dashmenu.json";
import DashboardMenu from "./dashboard_menu";
import { Avatar, IconButton } from "@material-ui/core";
import { useGlobalContext } from "../../controller/context_api";
import { AttachFile, Close, Telegram, Twitter } from "@material-ui/icons";
import SingleChat from "./singlechat";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../loading";
import axios from "../axios";
import Dashmenu from "../../views/mobile_dashnav";
import Dashdrop from "../../views/dashdrop";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import MicIcon from "@material-ui/icons/Mic";
import ImageIcon from "@material-ui/icons/Image";

import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";
import CloseIcon from "@material-ui/icons/Close";
// import { useRecorder } from "react-hook-audio-recorder";
// import AudioReactRecorder, { RecordState } from "audio-react-recorder";
import { storage } from "../admin/cmsform/firebase";
import { v4 as uuid } from "uuid";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import SlowMotionVideoIcon from "@material-ui/icons/SlowMotionVideo";
const ChatDashboard = () => {
  const {
    userimage,
    userfname,
    useremail,
    chatMessage,
    isLogin,
    isLoading,
    chat_reply,
    is_reply,
    setIs_reply,
    setChat_reply,
    chat_posi,
    issubscribed,
  } = useGlobalContext();
  const history = useNavigate();
  const [input, setInput] = useState("");
  const [audiourl, setAudiourl] = useState(null);
  const [isimage, setIsimage] = useState(false);
  const [imageurl, setImageurl] = useState(null);
  const [tempurl, setTempurl] = useState(null);
  const [messagetype, setMessagetype] = useState("text");
  const [recordState, setRecordstate] = useState(null);
  const [isRecording, setIsrecording] = useState(false);
  // const [audioURL, isRecording, startRecording, stopRecording] = useRecorder();
  const recorder = useRef(null);

  // --------------------handle all inputs----------
  const handleinput = (e) => {
    setInput(e.currentTarget.value);
  };

  const handleimage = (e) => {
    const tempurl = window.URL.createObjectURL(e.currentTarget.files[0]);
    const imgurl = e.currentTarget.files[0];
    setImageurl(imgurl);
    setTempurl(tempurl);
    // console.log(e.currentTarget.value, url);
  };

  // useEffect(()=>{console.log(chat_reply)},[chat_reply])

  const handleRecord = (e) => {
    // setIsrecording(true);
    // setAudiourl(null);
    // setImageurl(null);
    // setTempurl(null);
    // setRecordstate(RecordState.START);
    // console.log("sarted...");
  };
  const stopRecord = (e) => {
    // setIsrecording(true);
    // setRecordstate(RecordState.STOP);
    // console.log("stoped");
  };

  const cancelRecord = (e) => {
    setIsrecording(false);
    console.log("canceled");
  };
  // ----------------------------record--------------------
  const submitRecord = async (audioData) => {
    // e.preventDefault();
    setIsrecording(false);
    setAudiourl(audioData);
    const audioURL = audioData; //audiourl;

    // ----------------post to firebase and get url-------------
    if (audioURL) {
      const audioref = ref(storage, `jeax_audio_msg/${"audio_" + uuid()}`);

      uploadBytes(audioref, audioURL).then(() => {
        getDownloadURL(audioref).then(async (url) => {
          let URLaudio = url;
          const time = new Date();
          await axios
            .post("/newmessage", {
              sender: useremail,
              user: userfname,
              time: time,
              message: [
                { type: "audio" },
                { message: null },
                { image: null },
                { audio: URLaudio },
                { reply: chat_reply },
              ],
            })
            .then((res) => {
              setInput("");
              setChat_reply(null);
              setImageurl(null);
              setTempurl(null);
              setIs_reply(false);
              setAudiourl(null);
              console.log("successful");
            })
            .catch((err) => {
              console.log(err);
            });
        });
      });
    }
  };
  // ------------message-------------
  const handleMessage = async (e) => {
    e.preventDefault();
    const time = new Date();
    if (input === "") {
    } else {
      if (imageurl !== null) {
        // -------------get image url-------
        const imageref = ref(
          storage,
          `jeax_image_msg/${"image_msg_" + uuid()}`
        );

        uploadBytes(imageref, imageurl).then(() => {
          getDownloadURL(imageref).then(async (url) => {
            let URLimage = url;
            await axios
              .post("/newmessage", {
                sender: useremail,
                user: userfname,
                time: time,
                message: [
                  { type: "text" },
                  { message: input },
                  { image: URLimage },
                  { audio: null },
                  { reply: chat_reply },
                ],
              })
              .then((res) => {
                setInput("");
                setChat_reply(null);
                setImageurl(null);
                setTempurl(null);
                setIs_reply(false);
                setAudiourl(null);
                console.log("successful");
              })
              .catch((err) => {
                console.log(err);
              });
          });
        });
      } else {
        // -------------get image url-------
        console.log(chat_reply);
        await axios
          .post("/newmessage", {
            sender: useremail,
            user: userfname,
            time: time,
            message: [
              { type: "text" },
              { message: input },
              { image: null },
              { audio: null },
              { reply: chat_reply },
            ],
          })
          .then((res) => {
            setInput("");
            setChat_reply(null);
            setImageurl(null);
            setTempurl(null);
            setIs_reply(false);
            setAudiourl(null);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const cancleReply = () => {
    setChat_reply(null);
    setIs_reply(false);
  };

  const lastChat = useRef(null);

  useEffect(() => {
    const last_chat = lastChat.current;

    last_chat?.scrollTo(0, chat_posi);
  }, [chatMessage]);

  const [isauth, setIsauth] = useState(false);
  useMemo(() => {
    if (isLogin !== false) {
      setIsauth(true);
    }
  }, [isLogin]);

  useEffect(() => {
    if (isauth) {
    } else {
      history("/login", { replace: true });
    }
  }, []);

  const chat_tele = useRef(null);
  const showTele = () => {
    const chatt = chat_tele.current;
    chatt.classList.toggle("show_chat_tele");
  };

  //  const navi =()=>{
  //   history("https://t.me/+--mP6Fv0Gfk5MTI0");
  //  }

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <section className="dash_container">
          <Navigation />
          <Dashmenu color={"rgb(1,1,26)"} />
          <Dashdrop />
          {/* ////////////////////////////////////////////////////////////////// */}

          <article className="dash_body">
            <DashboardMenu />
            {/* /////////////////right content/////////////////////////////////// */}
            <div className="dash_l_content" style={{ overflow: "hidden" }}>
              <section
                className="chat_body_cont"
                style={{
                  backgroundImage: `url('../../../../graphics/chat_background.png')`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                {/* /////////chat head///////////////// */}
                <article className="chat_head">
                  <IconButton className="chat_tele" onClick={showTele}>
                    <a
                      href="https://t.me/+--mP6Fv0Gfk5MTI0"
                      className="chat_tele_tulips"
                      ref={chat_tele}
                    >
                      chat via Telegram
                    </a>
                    <MoreVertIcon />
                  </IconButton>
                  <div className="chat_tags">
                    <span className="chat_logo">Chatroom</span>{" "}
                    <div className="chat_ava">
                      <Avatar src="../../../graphics/avatar.ico" />
                      {/* <span className='chat_notify'>0</span> */}
                    </div>
                  </div>
                </article>
                {/* ////////chat body//////////////// */}
                <article className="chat_body" ref={lastChat}>
                  {chatMessage.map((props, index) => {
                    console.log(props);
                    return <SingleChat props={props} key={index} />;
                  })}
                </article>
                {/* ///////////////chat control//////////// */}
                <article className="chat_control">
                  <div className="chat_message_cont">
                    {is_reply && (
                      <div className="reply_modal">
                        {/* {chat_reply?.message[1].message} */}
                        {chat_reply?.message[0].type == "audio" ? (
                          <div className="message_audio">
                            <div className="message_audio_btn">
                              <SlowMotionVideoIcon
                                style={{ fontSize: "25px" }}
                                className="audio_btn_icon"
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="message_text">
                            {chat_reply?.message[2].image !== null && (
                              <img src={chat_reply?.message[2].image} />
                            )}
                            {chat_reply?.message[1].message}
                          </div>
                        )}
                        <span className="reply_close" onClick={cancleReply}>
                          <Close />
                        </span>
                      </div>
                    )}

                    <form onSubmit={handleMessage}>
                      {/* <div style={{ postion: "fixed" }}>
                        {audiourl && (
                          <audio controls>
                            <source src={audiourl} type="audio/wav" />
                          </audio>
                        )}
                        {imageurl && <img src={imageurl} />}
                      </div> */}
                      <input
                        type="text"
                        name="message"
                        id="message"
                        value={input}
                        onChange={handleinput}
                        autoComplete="none"
                        placeholder="Send a message..."
                      />
                      {/* ----------image input */}
                      <input
                        type="file"
                        accept="image/png, image/gif, image/jpeg"
                        id="image"
                        name="image"
                        disabled={isRecording}
                        onChange={handleimage}
                        style={{ display: "none" }}
                      />
                      {/* --------------audio input */}
                      {/* <AudioReactRecorder
                        state={recordState}
                        onStop={submitRecord}
                        ref={recorder}
                        canvasWidth={0}
                        canvasHeight={0}
                      /> */}
                      {/* ----------action buttons-------------- */}
                      <div className="form_button_cont">
                        {/* ----------------------attached image */}
                        <div
                          className="form_image"
                          style={{
                            backgroundImage: `url(${tempurl ? tempurl : ""})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                          }}
                        >
                          <label htmlFor="image" className="attach">
                            <ImageIcon
                              style={{
                                fontSize: "20px",
                              }}
                            />
                          </label>
                        </div>
                        <div className="form_button" onClick={handleMessage}>
                          <IconButton type="submit" disabled={isRecording}>
                            <Telegram
                              className={isRecording && "trans"}
                              style={{
                                fontSize: "15px",
                                margin: "5px",
                              }}
                            />
                          </IconButton>
                        </div>

                        <div className="form_button">
                          {isRecording ? (
                            <div className="record_action">
                              <div className="record_send" onClick={stopRecord}>
                                <MicIcon style={{ fontSize: "15px" }} />
                              </div>
                              <div
                                className="record_cancel"
                                onClick={cancelRecord}
                              >
                                <CloseIcon style={{ fontSize: "15px" }} />
                              </div>
                            </div>
                          ) : (
                            <IconButton type="submit" onClick={handleRecord}>
                              <MicIcon
                                style={{ fontSize: "15px", margin: "5px" }}
                              />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </article>

                {/* /////////////////////////////// */}
              </section>
            </div>
          </article>
        </section>
      )}
    </>
  );
};

export default ChatDashboard;
