import React, {
  useState,
  useEffect,
  useContext,
  useReducer,
  useMemo,
  useLayoutEffect,
} from "react";
import { Reducer } from "./reducer";
import users from "../model/users.json";
import topics from "../model/topics.json";
import myquestion from "../model/ques.json";
import messages from "../model/messages.json";
import drill_performance from "../model/drill_p.json";
import axios from "../views/axios";
import { useNavigate } from "react-router-dom";
import Pusher from "pusher-js";
import { FlashOnOutlined } from "@material-ui/icons";

const AppContext = React.createContext();

const initialState = { loading: false };

const AppProvider = ({ children }) => {
  const [subject, setSubject] = useState([]);
  //const history = useNavigate()

  useEffect(async () => {
    await axios.get("/subjects").then((res) => {
      setSubject(res.data);
    });
  }, []);
  // console.log(subject)

  const [isLoading, setIsLoading] = useState(true); //set back to true
  const [isload, setisload] = useState(false); //set back to true
  const [isPreloader, setisPreloader] = useState(false);
  const [isLogin, setisLogin] = useState(false); //set back to false
  const [isAuth, setIsauth] = useState(false);

  const [ismodalOpen, setismodalOpen] = useState(false);
  // /dashboard js
  const [ismobile, setIsmobile] = useState(false);
  const [ishome, setisHome] = useState(false);
  const [isprofile, setisProfile] = useState(false);
  const [iscourse, setisCourse] = useState(false);
  const [isstats, setisStats] = useState(false);
  const [istest, setisTest] = useState(false);
  const [ischat, setisChat] = useState(false);
  const [islive, setisLive] = useState(false);
  const [isnotify, setisNotify] = useState(false);
  const [iscms, setisCms] = useState(false);
  const [isref, setIsref] = useState(false);
  const [isquiz, setIsquiz] = useState(false);
  //////////////////////////////////////
  const handleMenu = (e) => {
    setIsmobile(false);
    const txt = e.currentTarget.textContent;
    if (txt === "Home") {
      setisHome(true);
      setisProfile(false);
      setisCourse(false);
      setisStats(false);
      setisTest(false);
      setisChat(false);
      setisLive(false);
      setisNotify(false);
      setisCms(false);
      setIsquiz(false);
      setIsref(false);
    }
    if (txt === "Profile") {
      setisHome(false);
      setisProfile(true);
      setisCourse(false);
      setisStats(false);
      setisTest(false);
      setisChat(false);
      setisLive(false);
      setisNotify(false);
      setisCms(false);
      setIsquiz(false);
      setIsref(false);
    }
    if (txt === "Courses") {
      setisHome(false);
      setisProfile(false);
      setisCourse(true);
      setisStats(false);
      setisTest(false);
      setisChat(false);
      setisLive(false);
      setisNotify(false);
      setisCms(false);
      setIsquiz(false);
      setIsref(false);
    }
    if (txt === "Statistics") {
      setisHome(false);
      setisProfile(false);
      setisCourse(false);
      setisStats(true);
      setisTest(false);
      setisChat(false);
      setisLive(false);
      setisNotify(false);
      setisCms(false);
      setIsquiz(false);
      setIsref(false);
    }
    if (txt === "Test drilling") {
      setisHome(false);
      setisProfile(false);
      setisCourse(false);
      setisStats(false);
      setisTest(true);
      setisChat(false);
      setisLive(false);
      setisNotify(false);
      setisCms(false);
      setIsquiz(false);
      setIsref(false);
    }
    if (txt === "Chatroom") {
      setisHome(false);
      setisProfile(false);
      setisCourse(false);
      setisStats(false);
      setisTest(false);
      setisChat(true);
      setisLive(false);
      setisNotify(false);
      setisCms(false);
      setIsquiz(false);
      setIsref(false);
    }
    if (txt === "Live meeting") {
      setisHome(false);
      setisProfile(false);
      setisCourse(false);
      setisStats(false);
      setisTest(false);
      setisChat(false);
      setisLive(true);
      setisNotify(false);
      setisCms(false);
      setIsquiz(false);
      setIsref(false);
    }
    if (txt === "Notification") {
      setisHome(false);
      setisProfile(false);
      setisCourse(false);
      setisStats(false);
      setisTest(false);
      setisChat(false);
      setisLive(false);
      setisNotify(true);
      setisCms(false);
      setIsquiz(false);
      setIsref(false);
    }
    if (txt === "CMS") {
      setisHome(false);
      setisProfile(false);
      setisCourse(false);
      setisStats(false);
      setisTest(false);
      setisChat(false);
      setisLive(false);
      setisNotify(false);
      setisCms(true);
      setIsquiz(false);
      setIsref(false);
    }
    if (txt === "Referral") {
      setisHome(false);
      setisProfile(false);
      setisCourse(false);
      setisStats(false);
      setisTest(false);
      setisChat(false);
      setisLive(false);
      setisNotify(false);
      setisCms(false);
      setIsquiz(false);
      setIsref(true);
    }
    if (txt === "Continous quiz") {
      setisHome(false);
      setisProfile(false);
      setisCourse(false);
      setisStats(false);
      setisTest(false);
      setisChat(false);
      setisLive(false);
      setisNotify(false);
      setisCms(false);
      setIsref(false);
      setIsquiz(true);
    }
  };

  ////////////////////logged user info///////////////////////// //
  const [loginfo, setLoginfo] = useState("");
  const [loggeduser, setLoggeduser] = useState("");
  const [allusers, setAllusers] = useState([]);
  const [testimonies, settestimonies] = useState([]);
  const [drillquestions, setDrillquestions] = useState([]);

  // let c_array = [];
  //     let course = loggeduser.map((user) => {
  //       const { course } = user;

  //       for (let i = 0; i < course?.length; i++) {
  //         c_array = [...c_array, course[i].subject];
  //       }
  //       return course;
  //     });
  //     course = course[0];
  //     setUsercourse(course);
  //     setCoursearray(c_array);

  //-------------------- getting log info
  useMemo(async () => {
    if (loginfo !== "") {
      let config = { headers: { authorization: `Bearer ${loginfo.token}` } };
      await axios.get(`/dashboard/${loginfo.id}`, config).then(async (res) => {
        const loguser = [res.data.user];
        // ------------------getting user question-----------------------------
        await axios.get("/questions").then((res) => {
          const allques = res.data;
          // --------------------------------
          let c_array = [];
          loguser.map((user) => {
            const { course } = user;

            for (let i = 0; i < course?.length; i++) {
              c_array = [...c_array, course[i].subject];
            }
          });
          const c1 = allques.filter(
            (quest) => quest.subject.toLowerCase() == c_array[0]?.toLowerCase()
          );
          const c2 = allques.filter(
            (quest) => quest.subject.toLowerCase() == c_array[1]?.toLowerCase()
          );
          const c3 = allques.filter(
            (quest) => quest.subject.toLowerCase() == c_array[2]?.toLowerCase()
          );
          const c4 = allques.filter(
            (quest) => quest.subject.toLowerCase() == c_array[3]?.toLowerCase()
          );
          const c5 = allques.filter(
            (quest) => quest.subject.toLowerCase() == c_array[4]?.toLowerCase()
          );
          // -------------------------------
          setDrillquestions([...c1, ...c2, ...c3, ...c4, ...c5]);
        });
        // ------------------------end------------------------
        setLoggeduser(loguser);
        setisLogin(true);
      });
    }
  }, [loginfo]);

  useEffect(async () => {
    await axios.get("/getusers").then((res) => {
      // console.log()
      setAllusers(res.data);
    });
    await axios.get("/testimonies").then((res) => {
      // console.log(res.data)
      settestimonies(res.data);
    });
  }, []);

  const Updateuser = async () => {
    if (loginfo !== "") {
      let config = { headers: { authorization: `Bearer ${loginfo.token}` } };
      await axios.get(`/dashboard/${loginfo.id}`, config).then((res) => {
        //console.log(res.data.user)
        if (res) {
          setLoggeduser([res.data.user]);
        }
      });
    }
  };

  //-------------------------- /activating refresh
  useEffect(async () => {
    setIsLoading(true);
    await axios
      .get("/refresh", { withCredentials: true })
      .then((res) => {
        //console.log(res)
        setLoginfo(res.data);
        setIsLoading(false);
        setisLogin(true);
      })
      .catch((err) => {
        console.error(err);
      });
    setIsLoading(false);
    setisLogin(false);
  }, []);

  // //setting user info///////////////////////////
  const [userid, setUserid] = useState([]);
  const [userroles, setUserroles] = useState([]);
  const [userplan, setUserplan] = useState("");
  const [planexpiry, setPlanexpiry] = useState("");
  const [issubscribed, setissubscribed] = useState("");
  const [userimage, setUserimage] = useState("");
  const [userfname, setUserfname] = useState("");
  const [userlname, setUserlname] = useState("");
  const [userdob, setUserdob] = useState([]);
  const [useremail, setUseremail] = useState([]);
  const [userpassword, setUserpassword] = useState([]);
  const [userstate, setUserstate] = useState([]);
  const [usercountry, setUsercountry] = useState([]);
  const [usergender, setUsergender] = useState([]);
  const [usercourse, setUsercourse] = useState([]);
  const [drill_progress, setDrill_progress] = useState([]);
  const [notification, setNotification] = useState([]);
  const [ref_id, setRef_id] = useState("");
  const [balance, setBalance] = useState(0);
  const [quizinfo, setQuizinfo] = useState({});
  // console.log(loggeduser);
  //  /////////////set info//////////////////////////////

  const [coursearray, setCoursearray] = useState([]);

  const loaduserinfo = () => {
    //   let avatar = loggeduser.map((user)=>{
    //     const {image} = user;
    //     return image
    // })
    // avatar =avatar[0]
    // setUserimage(avatar);

    let id = loggeduser.map((user) => {
      const { _id } = user;
      return _id;
    });
    id = id[0];
    setUserid(id);

    let ref = loggeduser.map((user) => {
      const { ref_id } = user;
      return ref_id;
    });
    ref = ref[0];
    setRef_id(ref);

    let bal = loggeduser.map((user) => {
      const { balance } = user;
      return balance;
    });
    bal = bal[0];
    setBalance(bal);

    let q_info = loggeduser.map((user) => {
      const { quiz } = user;
      return quiz;
    });
    q_info = q_info[0];
    setQuizinfo(q_info[0]);

    let roles = loggeduser.map((user) => {
      const { roles } = user;
      return roles;
    });
    roles = roles[0];
    setUserroles(roles);

    let notify = loggeduser.map((user) => {
      const { notifications } = user;
      return notifications;
    });
    notify = notify[0];
    setNotification(notify);

    let plan = loggeduser.map((user) => {
      const { sub_plan } = user;
      return sub_plan;
    });
    plan = plan[0];
    setUserplan(plan);

    let progress = loggeduser.map((user) => {
      const { drill_performance } = user;
      return drill_performance;
    });
    progress = progress[0];
    setDrill_progress(progress);

    let expire = loggeduser.map((user) => {
      const { plan_expiry } = user;
      return plan_expiry;
    });
    expire = expire[0];
    setPlanexpiry(expire);

    let sub = loggeduser.map((user) => {
      const { issubscribed } = user;
      return issubscribed;
    });
    sub = sub[0];
    setissubscribed(sub);

    let state = loggeduser.map((user) => {
      const { state } = user;
      return state;
    });
    state = state[0];
    setUserstate(state);

    let country = loggeduser.map((user) => {
      const { country } = user;
      return country;
    });
    country = country[0];
    setUsercountry(country);

    let gender = loggeduser.map((user) => {
      const { gender } = user;
      return gender;
    });
    gender = gender[0];
    setUsergender(gender);

    let c_array = [];
    let course = loggeduser.map((user) => {
      const { course } = user;

      for (let i = 0; i < course?.length; i++) {
        c_array = [...c_array, course[i].subject];
      }
      return course;
    });
    course = course[0];
    setUsercourse(course);
    setCoursearray(c_array);
    // console.log(coursearray);
    // //////

    let fname = loggeduser.map((user) => {
      const { first_name } = user;
      return first_name;
    });
    fname = fname[0];
    setUserfname(fname);

    let lname = loggeduser.map((user) => {
      const { last_name } = user;
      return last_name;
    });
    lname = lname[0];
    setUserlname(lname);

    let email = loggeduser.map((user) => {
      const { email } = user;
      return email;
    });
    email = email[0];
    setUseremail(email);

    let password = loggeduser.map((user) => {
      const { password } = user;
      return password;
    });
    password = password[0];
    setUserpassword(password);

    let dob = loggeduser.map((user) => {
      const { date_of_birth } = user;
      return date_of_birth;
    });
    dob = dob[0];
    setUserdob(dob);
  };

  useEffect(() => {
    if (loggeduser !== "") {
      loaduserinfo();
    }
  }, [loggeduser]);

  // ///getting all videos
  const [videos, setVideos] = useState([]);

  useEffect(async () => {
    await axios
      .get("/getvideos")
      .then((res) => {
        setVideos(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  //console.log(videos)

  // //////////////////test drilling questions////////////////////////

  // ////////////////// filter drill questions to get user selected question

  // const [raw_questions, setRaw_questions] = useState(myquestion);

  const [questionsS, setQuestionsS] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [exam_year, setExam_year] = useState("2022");
  useEffect(() => {
    setExam_year(exam_year);
  }, [exam_year]);

  // write a function that generate random question up to 100

  const Question_gen = () => {
    const q_length = drillquestions.length; //filter by exam_year
    let q_array = [];
    let num;
    let selected_question = [];

    for (let i = 0; i < q_length; i++) {
      num = Math.floor(Math.random() * q_length);
      let index = i;
      if (q_array.includes(num)) {
        q_array[index] = num + 1;
      } else {
        q_array[index] = num;
      }
    }
    const quest = drillquestions; //filter by exam_year
    const selected = q_array.filter((array, index) => index < 100);

    for (let i = 0; i < selected.length; i++) {
      let in_dex = selected[i];
      // console.log(in_dex);
      const userquestion = quest.filter((question, index) => index == in_dex);

      selected_question.push(userquestion[0]);
    }

    setQuestionsS(selected_question);
  };

  // call the function
  useEffect(() => {
    Question_gen();
  }, [exam_year]);
  // -----select question by subject
  const [activeSub, setActiveSub] = useState([]);
  useEffect(() => {
    let subjects = {};
    for (let i = 0; i < questionsS.length; i++) {
      subjects[questionsS?.[i]?.subject] = questionsS?.[i]?.subject;
    }
    const userSubject = Object.values(subjects);
    setActiveSub(userSubject);
    // ---------------------------------
  }, [questionsS]);
  // -------------------------------------- filter questons
  useEffect(() => {
    const userQuestions = [];

    for (let i = 0; i < activeSub?.length; i++) {
      let sub = activeSub[i];

      let mainQuestion = [];
      for (let i = 0; i < questionsS?.length; i++) {
        if (questionsS[i]?.subject == sub) {
          mainQuestion.push(questionsS[i]);
        } else {
        }
      }
      userQuestions.push(...mainQuestion);
    }
    setQuestions(userQuestions);
    // ---------------------------------
  }, [activeSub]);

  // //////////////////////configuring grading

  const [score, Setscore] = useState([]);
  // const [id,Setid] =useState('')
  // const [answer,Setanswer] =useState('')
  // const [option,Setoption] =useState('')

  const CheckAnswer = (props) => {
    const id = props.id + 1;
    const _id = props._id;
    const answer = props.answer;
    const option = props.e.currentTarget.value;

    //  add options to main question
const editedQuestion=questions?.map((ques,ind)=>{
  if(ques._id==_id){
  ques.option = option;
  return ques;
  }else{return ques}
})

setQuestions(editedQuestion);

    //  check if score exist
    const isset = score.find((score) => score.id === id) || null;

    if (isset === null) {
      // check correct answer
      if (option === answer) {
        const newscore = { id: id, answer: option, score: 4 };
        Setscore([...score, newscore]);
      } else if (option !== answer) {
        const newscore = { id: id, answer: option, score: 0 };
        Setscore([...score, newscore]);
      }
      // console.log(score);
    } else {
      const e_id = isset.id;
      const other_score = score.filter((score) => score.id !== e_id);
      if (option === answer) {
        const new_score = { id: e_id, answer: option, score: 4 };
        const modified_score = [...other_score, new_score];
        Setscore(modified_score);
      } else if (option !== answer) {
        const new_score = { id: e_id, answer: option, score: 0 };
        const modified_score = [...other_score, new_score];
        Setscore(modified_score);
      }
    }
  };

  // ///////////configuring quiz grading
  const [quizallow, setQuizallow] = useState(false); /////change to false
  const [quizscore, Setquizscore] = useState([]);
  const [totalquizscore, Settotalquizscore] = useState(
    quizinfo?.score?.toString()
  );
  const [quizid, Setquizid] = useState("");
  const [q_isload, setQ_isload] = useState(false);
  // const [id,Setid] =useState('')
  // const [answer,Setanswer] =useState('')
  // const [option,Setoption] =useState('')

  const QuizAnswer = (props) => {
    const id = props.id + 1;
    Setquizid(props.q_id);
    const answer = props.answer;
    const option = props.e.currentTarget.value;
    //  check if score exist
    const isset = quizscore.find((score) => score.id === id) || null;

    if (isset === null) {
      // check correct answer
      if (option === answer) {
        const newscore = { id: id, answer: option, score: 1 };
        Setquizscore([...quizscore, newscore]);
      } else if (option !== answer) {
        const newscore = { id: id, answer: option, score: 0 };
        Setquizscore([...quizscore, newscore]);
      }
      // console.log(score);
    } else {
      const e_id = isset.id;
      const other_score = quizscore.filter((score) => score.id !== e_id);
      if (option === answer) {
        const new_score = { id: e_id, answer: option, score: 1 };
        const modified_score = [...other_score, new_score];
        Setquizscore(modified_score);
      } else if (option !== answer) {
        const new_score = { id: e_id, answer: option, score: 0 };
        const modified_score = [...other_score, new_score];
        Setquizscore(modified_score);
      }
    }
  };

  const SubmitQuiz = async () => {
    let accum = 0;
    for (let i = 0; i < quizscore?.length; i++) {
      accum += quizscore[i].score;
    }
    //console.log(quizid,useremail,quizinfo)
    //setQuizallow(true);
    setQ_isload(true);
    Settotalquizscore(accum);
    await axios
      .post("/submitquiz", {
        quiz_id: quizid,
        score: accum,
        username: useremail,
      })
      .then((res) => {
        Updateuser();
        //setQuizallow(true);
        setQ_isload(false);
      })
      .catch((err) => {
        setQ_isload(false);
        console.log(err);
      });
  };

  // ////////////////////configuration of drill submit
  const [totalScore, SettotalScore] = useState(0);
  const [isstart, Setisstart] = useState(false);
  const [isfinish, Setfinish] = useState(true);
  const [isend, setIsend] = useState(false);
  const [isemergency, setIsemergency] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isprevious, Setisprevious] = useState(false);
  const [translateposition, setTranslateposition] = useState(0);

  let [counter, setCounter] = useState(0);
  useEffect(() => {
    setCounter(counter);
  }, [counter]);
  const [hour, Sethour] = useState(0);
  const [minutes, Setminutes] = useState(0);
  const [seconds, Setseconds] = useState(0);
  const [updating, Setupdating] = useState(false);
  const [ismodal, Setismodal] = useState(false);
  const [timer, Settimer] = useState(null);

  let startCountDown = timer;
  // /////////////////configuring drill start

  //  ///////////////////////////////////////////////////////////
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  // ////////////change question position//////////////////////

  const changeQuestion = (e) => {
    counter++;
    setCounter(counter);
    Setisprevious(true);

    let position = counter * 100;
    if (counter > 99) {
      setIsend(true);
    } else {
      setTranslateposition(position);
    }
  };

  const previousQuestion = (e) => {
    counter--;
    setCounter(counter);
    let position = counter * 100;
    if (counter <= 0) {
      setTranslateposition(0);
      Setisprevious(false);
    } else {
      setTranslateposition(position);
    }
  };

  // //////////configure timeout

  // //////////////////execute countdown

  const stopCountDown = () => {
    Settimer(clearInterval(timer));
    Settimer(null);
    // Sethour(0)
    // Setminutes(0)
    // Setseconds(10)
    Setupdating(!updating);
  };

  const changeModal = () => {
    setIsemergency(true);
  };

  // ///////////////////////////////
  const countDown = () => {
    let s = seconds;
    let m = minutes;
    let h = hour;

    Settimer(
      setInterval(() => {
        s--;
        if (s <= 0 && m <= 0 && h <= 0) {
          changeModal();
        } else if (s <= 0) {
          s = 59;
          Setseconds(s);
          m--;
          if (m <= 0 && h >= 1) {
            m = 59;
            Setminutes(m);
            h--;
            Sethour(h);
          } else {
            Setminutes(m);
          }
        } else {
          Setseconds(s);
        }
      }, 1000)
    );
  };

  // ////////////////////////altering value

  useEffect(() => {
    Sethour(1);
    Setminutes(50);
    Setseconds(59);
    console.log("updating...");
  }, [updating]);

  // ///////////////////
  // /////////////////////// start a drill

  const StartDrill = async () => {
    Setupdating(!updating);
    Setisstart(true);
    Setfinish(false);
    setIsend(false);
    Setisprevious(false);
    setTranslateposition(0);
    setCounter(0);
    SettotalScore(0);
    Question_gen(); //generate new question
    Setscore([]);

    setTimeout(() => {
      countDown();
      console.log("starting...");
    }, 2000);
  };

  // ///////////////submit drill
  const SubmitDrill = async () => {
    setSubmitted(true);
    setIsemergency(false);
    stopCountDown();
    let accum = 0;
    for (let i = 0; i < score?.length; i++) {
      accum += score[i].score;
    }
    SettotalScore(accum);
    Setisstart(false);
    Setfinish(true);

    await axios
      .post("/updatedrill", { username: useremail, score: accum })
      .then((res) => {
        Updateuser();
        // console.log(res);
      })
      .catch((err) => {});
  };

  // ///////////topic page js///////////////
  const [isphy, setIsphy] = useState(false);
  const [ismath, setIsmath] = useState(false);
  const [ischem, setIschem] = useState(false);
  const [iseng, setIseng] = useState(false);
  const [isbio, setIsbio] = useState(false);
  const [topic, setTopic] = useState(topics);

  // //this toggle show and hide of topic overlay
  const topicDropdown = (props) => {
    const { e } = props;

    const ref_id = e.currentTarget.dataset.id;

    if (ref_id === "physics") {
      setIsphy(!isphy);
      setIsmath(false);
      setIschem(false);
      setIsbio(false);
      setIseng(false);
    } else if (ref_id === "chemistry") {
      setIsphy(false);
      setIsmath(false);
      setIschem(!ischem);
      setIsbio(false);
      setIseng(false);
    } else if (ref_id === "mathematics") {
      setIsphy(false);
      setIsmath(!ismath);
      setIschem(false);
      setIsbio(false);
      setIseng(false);
    } else if (ref_id === "english") {
      setIsphy(false);
      setIsmath(false);
      setIschem(false);
      setIsbio(false);
      setIseng(!iseng);
    } else if (ref_id === "biology") {
      setIsphy(false);
      setIsmath(false);
      setIschem(false);
      setIsbio(!isbio);
      setIseng(false);
    }
  };

  // /this set the topic to be learnt
  const [interestedTopic, setInterestedTopic] = useState("");
  const [learnposition, setlearnposition] = useState("");

  const userTopic = (props) => {
    const { e } = props;
    const topic_id = e.target.dataset.id;
    //console.log(topic_id)
    setInterestedTopic(topic_id);
  };
  // //////////statistics js//////////////////////

  // ///////////chat room///////////////////
  const [chatMessage, setchatMessage] = useState([]);
  const [chat_id, setChat_id] = useState(null);
  const [chat_reply, setChat_reply] = useState(null);
  const [is_reply, setIs_reply] = useState(false);
  const [chat_posi, setChat_posi] = useState(null);

  useEffect(async () => {
    await axios
      .get("/getmessage")
      .then((res) => {
        setchatMessage(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const Getreply = () => {};

  // ////-------------trigger pusher
  useEffect(() => {
    const pusher = new Pusher("740a322764743822fe48", {
      cluster: "eu",
    });

    const channel = pusher.subscribe("messages");
    channel.bind("inserted", (newmessage) => {
      //alert(JSON.stringify(data));
      // console.log(newmessage);
      setchatMessage([...chatMessage, newmessage]);
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [chatMessage]);

  // //////////////////////////////////
  let [state, Dispatch] = useReducer(Reducer, initialState);

  const [adminposition, Setadminposition] = useState(0);
  return (
    <AppContext.Provider
      value={{
        isLoading,
        isload,
        setisload,
        setIsLoading,
        handleSubmit,
        isLogin,
        isAuth,
        loginfo,
        setLoginfo,
        setisLogin,
        isPreloader,
        setisPreloader,
        ishome,
        setisHome,
        isprofile,
        setisProfile,
        iscourse,
        setisCourse,
        isstats,
        setisStats,
        istest,
        setisTest,
        islive,
        setisLive,
        ischat,
        setisChat,
        isnotify,
        setisNotify,
        iscms,
        isref,
        setIsref,
        setisCms,
        setIsquiz,
        isquiz,
        handleMenu,
        ref_id,
        userid,
        userimage,
        useremail,
        userstate,
        usercountry,
        userroles,
        userpassword,
        userfname,
        userlname,
        balance,
        quizinfo,
        usergender,
        userplan,
        planexpiry,
        issubscribed,
        setLoggeduser,
        loggeduser,
        usercourse,
        notification,
        userdob,
        questions,
        setQuestions,
        changeQuestion,
        counter,
        translateposition,
        isend,
        submitted,
        setSubmitted,
        isprevious,
        previousQuestion,
        topicDropdown,
        ischem,
        ismath,
        iseng,
        isbio,
        isphy,
        topic,
        userTopic,
        drill_progress,
        chatMessage,
        setLoginfo,
        loginfo,
        testimonies,
        subject,
        setSubject,
        videos,
        setlearnposition,
        interestedTopic,
        CheckAnswer,
        totalScore,
        SubmitDrill,
        totalquizscore,
        QuizAnswer,
        SubmitQuiz,
        q_isload,
        setQ_isload,
        quizallow,
        setQuizallow,
        isstart,
        isfinish,
        isemergency,
        StartDrill,
        hour,
        minutes,
        seconds,
        ismodal,
        adminposition,
        Setadminposition,
        ismodalOpen,
        setismodalOpen,
        Updateuser,
        setChat_id,
        chat_reply,
        Getreply,
        is_reply,
        setIs_reply,
        setChat_reply,
        chat_posi,
        setChat_posi,
        ismobile,
        setIsmobile,
        allusers,
        exam_year,
        setExam_year,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
