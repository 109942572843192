import { useGlobalContext } from "../../controller/context_api"
import CMSsubscription from './cmsform/cmssubscription'


const University = ()=>{
const {adminposition} = useGlobalContext()

    return(
        <article className="admin_form_component" style={{right:"-600%",transform:`translateX(-${adminposition}%)`}}>
            <CMSsubscription/>
        </article>
    )
}
export default University  