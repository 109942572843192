import { IconButton } from "@material-ui/core";
import {
  AttachFile,
  CloudUploadOutlined,
  ExpandMore,
} from "@material-ui/icons";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import AddIcon from "@material-ui/icons/Add";
import { useEffect, useRef, useState } from "react";
import axios from "../../axios";
import courses from "../../../model/courses.json";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import { storage } from "./firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuid } from "uuid";
import { useGlobalContext } from "../../../controller/context_api";
// import Loading from '../../init_loading'

const CmsVideo = () => {
  const { setIsLoading, setisload } = useGlobalContext();
  const [video_name, setVideo_name] = useState("Choose video");
  //---------------------- form data
  const [video, setVideo] = useState("");
  const [course, setCourse] = useState("Course");
  const [topic, setTopic] = useState("Topic");
  const [question, setQuestion] = useState("Question");
  //const [question, setQuestion] = useState(() => EditorState.createEmpty());
  const [option1, setOption1] = useState("option1");
  const [option2, setOption2] = useState("option2");
  const [option3, setOption3] = useState("option3");
  const [option4, setOption4] = useState("option4");
  const [answer, setAnswer] = useState("Answer");
  const modules = {
    toolbar: [
      [{ headers: [1, 2, 3, 4, 5, 6, false] }],
      [{ font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ script: "sub" }, { script: "super" }],
    ],
  };

  const initialstate = [{ id: 1, name: "note1", content: "" }];
  const [notearray, setNotearray] = useState(initialstate);

  const [mynotes, setMynotes] = useState([]);
  // const [mynotes,setMynotes] = useState(() => EditorState.createEmpty())
  // //------------form error
  const [iserror, setisError] = useState(false);
  const [error, setError] = useState("");
  const [issuccess, setisSuccess] = useState(false);
  const [success, setSuccess] = useState("");

  useEffect(() => {
    setNotearray(initialstate);
    setTimeout(() => {
      setAnswer("Answer");
      setQuestion("Question");
      setOption1("Option1");
      setOption2("Option2");
      setOption3("Option3");
      setOption4("Option4");
      setTopic("Topic");
      setCourse("Course");
      setVideo("");
      setVideo_name("Choose video");
      setisSuccess(false);
      setSuccess("");
    }, 3000);

    return () => {
      clearTimeout();
    };
  }, [success]);
  // -------------------------------------
  const handleFileInput = (e) => {
    const videocont = e.target.files[0];
    setisError(false);
    setVideo(videocont);
    const videoname = `${e.target.files[0].name.substring(0, 20) + "" + "..."}`;
    setVideo_name(videoname);
    const videotype = e.target.files[0].type;
  };

  // -------------------------
  const drop_cont_ref = useRef(null);

  const handleOption = (e) => {
    const drop_cont = drop_cont_ref.current;
    drop_cont.classList.toggle("cms_show");
  };
  // ----------------------------
  const handleCourse = (e) => {
    setCourse(e.target.value);
    const drop_cont = drop_cont_ref.current;
    drop_cont.classList.remove("cms_show");
  };

  // -------------------------------
  const handleTextInput = (e) => {
    const name = e.currentTarget.name;
    setisError(false);

    switch (name) {
      case "title":
        setTopic(e.currentTarget.value);
        break;
      case "question":
        setQuestion(e.currentTarget.value);
        break;
      case "answer":
        setAnswer(e.currentTarget.value);
        break;
      case "option1":
        setOption1(e.currentTarget.value);
        break;
      case "option2":
        setOption2(e.currentTarget.value);
        break;
      case "option3":
        setOption3(e.currentTarget.value);
        break;
      case "option4":
        setOption4(e.currentTarget.value);
        break;
    }
  };

  useEffect(() => {
    setMynotes(notearray);
  }, [notearray]);

  // const addNote =()=>{
  // const a_id = mynotes.length+1;
  // const a_name = `note${a_id}`
  // setNotearray([...mynotes, {"id":a_id,"name":a_name,"content":""}])
  // }

  const textareaChange = (e) => {
    const name = e.currentTarget.name;

    const newnotes = notearray.map((note) => {
      if (note.name == name) {
        note.content = e.currentTarget.value;
      }
      return note;
    });
    setNotearray([...newnotes]);
  };

  // ----------------file uploading
  const handleFileUpload = (e) => {
    e.preventDefault();
    const options = [option1, option2, option3, option4];

    if (video === "") {
      setisError(true);
      setError("please choose a video file");
    } else if (course === "Course") {
      setisError(true);
      setError("Choose a valid course name");
    } else if (topic === "Topic") {
      setisError(true);
      setError("Enter a topic");
    } else if (options === []) {
      setisError(true);
      setError("Please provide options");
    } else if (question === "Question") {
      setisError(true);
      setError("Please provide question");
    } else if (answer === "Answer") {
      setisError(true);
      setError("Please provide answer");
    } else if (mynotes === []) {
      setisError(true);
      setError("Please add notes");
    } else {
      setIsLoading(true);

      const videoref = ref(storage, `jaex_videos/${"jaex" + uuid()}`);
      uploadBytes(videoref, video).then((res) => {
        getDownloadURL(videoref).then(async (url) => {
          // uploaading to mongodb
          const Url = url;
          await axios
            .post("/newvideo", {
              course: course.toLowerCase(),
              videoUrl: Url,
              topic: topic,
              question: question,
              answer: answer,
              options: options,
              notes: [mynotes],
            })
            .then((res) => {
              setIsLoading(false);
              setisSuccess(true);
              setMynotes([]);
              setSuccess("successfully uploaded a new video");
              //console.log(res)
            })
            .catch((err) => {
              setisError(true);
              setError("Unable to upload video");
              setIsLoading(false);
            });
        });
      });
    }
  };

  return (
    <article className="cms_videoform_cont">
      <div className="cms_header">Video</div>
      {/* ---------------error------------------------- */}
      <div className="err_div">
        {iserror && (
          <article className="error">
            <ErrorOutlineIcon /> {error}
          </article>
        )}
      </div>
      <div className="err_div">
        {issuccess && (
          <article className="success">
            <CheckCircleOutlineIcon /> {success}
          </article>
        )}
      </div>
      {/* ----------------------------------------------------------- */}

      <form className="cms_form" onSubmit={handleFileUpload}>
        <div className="cms_form_div">
          <div className="cms_form_content">{video_name}</div>
          <label htmlFor="cfile" className="cms_label">
            <AttachFile />
            <input
              type="file"
              name="cfile"
              id="cfile"
              onChange={handleFileInput}
            />
          </label>
        </div>
        {/* ------------------------------------------------------- */}
        <div className="cms_form_div">
          <div className="cms_form_content">{course}</div>
          <div className="cms_drop" onClick={handleOption}>
            <ExpandMore />
          </div>
          <div className="cms_drop_content" ref={drop_cont_ref}>
            <article className="cms_drop_form">
              {courses.map((course, index) => {
                const { id, name } = course;

                return (
                  <div className="cms_drop_form_div" key={id}>
                    <input
                      type="radio"
                      name="courses"
                      id={name}
                      value={name}
                      onClick={handleCourse}
                    />
                    <label htmlFor={name}>{name}</label>
                  </div>
                );
              })}
            </article>
          </div>
        </div>
        {/* ------------------------------------------------------- */}
        <div className="cms_form_div">
          <input
            type="text"
            name="title"
            value={topic}
            onChange={handleTextInput}
          />
        </div>
        {/* ------------------------------------------------------- */}
        {/* ------------------------------------------------------- */}
        <div className="cms_form_div">
          {/* <textarea
            name="question"
            onChange={handleTextInput}
            placeholder="Description"
            className="single_note"
          ></textarea> */}

          <ReactQuill
            theme="snow"
            //value={question}
            placeholder="Question"
            onChange={setQuestion}
            className="single_note"
            modules={modules}
          />
        </div>
        {/* ------------------------------------------------------- */}
        <div className="cms_form_div">
          <input
            type="text"
            name="answer"
            value={answer}
            onChange={handleTextInput}
          />
        </div>
        {/* ------------------------------------------------------- */}
        <div className="cms_form_div">
          <input
            type="text"
            name="option1"
            value={option1}
            onChange={handleTextInput}
          />
        </div>
        {/* ------------------------------------------------------- */}
        <div className="cms_form_div">
          <input
            type="text"
            name="option2"
            value={option2}
            onChange={handleTextInput}
          />
        </div>
        {/* ------------------------------------------------------- */}
        <div className="cms_form_div">
          <input
            type="text"
            name="option3"
            value={option3}
            onChange={handleTextInput}
          />
        </div>
        {/* ------------------------------------------------------- */}
        <div className="cms_form_div">
          <input
            type="text"
            name="option4"
            value={option4}
            onChange={handleTextInput}
          />
        </div>
        <article className="cms_form_note_div">
          <div className="cms_form_note">
            {" "}
            <span className="note_title">Notes</span>{" "}
            <span className="note_button">
              {/* //onClick={addNote} */}
              <AddIcon />
              Add
            </span>{" "}
          </div>
          {/* ------------------------------------- */}
          {/* {notearray.map((note, index) => {
              let i_name = `note${index + 1}`;

              return (
                <textarea
                  name={i_name}
                  key={index}
                  onChange={textareaChange}
                  placeholder="New paragraph"
                  className="single_note"
                  maxLength="500"
                ></textarea>
              );
            })} */}
          {/* <div className="cms_form_div"> */}
          {/* <textarea
            name="question"
            onChange={handleTextInput}
            placeholder="Description"
            className="single_note"
          ></textarea> */}

          <ReactQuill
            theme="snow"
            //value={mynotes}
            onChange={setMynotes}
            className="single_note"
            modules={modules}
          />
          {/* <Editor mynotes={mynotes} onChange={setMynotes} /> */}

          {/* </div> */}
        </article>
        {/* ------------------------------------------------------- */}

        {/* <input type="text" name={i_name} key={index} onChange={textareaChange} value={note?.content}  className="single_note"/>*/}

        <div className="cms_buttons" onClick={handleFileUpload}>
          <ArrowRightAltIcon />
          Upload
        </div>
      </form>
    </article>
  );
};

export default CmsVideo;
