import Loading from './init_loading'

const Preloader = ()=>{

    return(
<section className='preloader'>
   <Loading/> 
    </section>
    )
}

export default Preloader;