import React from 'react'
import Navigation from './navigation'
import Footer from './footer'
import privacy from '../model/privacy.json'
import data from '../model/privacy_data.json'
import Mobilenav from './mobilenav'
import {Helmet} from "react-helmet"
const Privacy = () => {
  return (
    <section className="company_cont">
      <Helmet>
        <meta property="og:image" content="../../../graphics/home_og.png" />
        <meta
          name="description"
          content="Thank you for joining Jaex. We at Jaex (“Jaex”, “we”, “us”) respect
          your privacy and want you to understand how we collect, use, and share
          data about you. This Privacy Policy covers our data collection
          practices and describes your rights regarding your personal data."
        />
        <meta
          name="twitter:card"
          content="Thank you for joining Jaex. We at Jaex (“Jaex”, “we”, “us”) respect
          your privacy and want you to understand how we collect, use, and share
          data about you. This Privacy Policy covers our data collection
          practices and describes your rights regarding your personal data."
        />
        <meta
          name="keywords"
          content="jamb, computer based test, exam,cbt, jamb examination"
        />
        <meta property="og:title" content="Privacy policy" />
        <meta
          property="og:description"
          content="Thank you for joining Jaex. We at Jaex (“Jaex”, “we”, “us”) respect
          your privacy and want you to understand how we collect, use, and share
          data about you. This Privacy Policy covers our data collection
          practices and describes your rights regarding your personal data."
        />
        {/* <meta
          property="og:image"
          itemprop="image"
          content="https://firebasestorage.googleapis.com/v0/b/jaex-b9931.appspot.com/o/logo%2Fhome_og.png?alt=media&token=4842f49e-696f-4bfd-8bbe-4316d57dcc3a"
        /> */}
        <meta property="og:type" content="website" />

        <title>Privacy policy</title>
      </Helmet>
      <Navigation />
      <Mobilenav color={"rgb(1,1,26)"} />
      <article className="privacy_body">
        <h1>Privacy Policy</h1>
        <p>This Privacy Policy was last updated on November 03, 2022.</p>

        <p>
          Thank you for joining Jaex. We at Jaex (“Jaex”, “we”, “us”) respect
          your privacy and want you to understand how we collect, use, and share
          data about you. This Privacy Policy covers our data collection
          practices and describes your rights regarding your personal data.
        </p>
        <p>
          Unless we link to a different policy or state otherwise, this Privacy
          Policy applies when you visit or use the Jaex websites, mobile
          applications, or related services (the “Services”). It also applies to
          prospective customers of our business and enterprise products.
        </p>
        <p>
          <b>
            By using the Services, you agree to the terms of this Privacy
            Policy.
          </b>
           You shouldn’t use the Services if you don’t agree with this Privacy
          Policy or any other agreement that governs your use of the Services.
        </p>
        {/* ------------------------------------------------------------ */}

        <h1>Table of Content</h1>

        <ul>
          <li> 1. What Data We Get</li>
          <li> 2. What We Use Your Data For</li>
          <li> 3. Security</li>
          <li> 4. Your Rights</li>
          <li> 5. Updates & Contact Info</li>
        </ul>
        {/* ------------------------------------------------------------------ */}

        <h1>1. What Data We Get</h1>
        <p>
          We collect certain data from you directly, like information you enter
          yourself, data about your consumption of. We also collect some data
          automatically, like information about your device and what parts of
          our Services you interact with or spend time using. All data listed in
          this section is subject to the following processing activities:
          collecting, recording, structuring, storing, altering, retrieving,
          encrypting, pseudonymizing, erasing, combining, and transmitting.
        </p>

        <h2> 1.1 Data You Provide to Us</h2>

        <table>
          <th>
            <tr className="he_title">Category of Personal Data</tr>
            <tr className="he_desc">Description</tr>
            <tr className="he_info">Legal Basis for Processing</tr>
          </th>
          {/* -------------------------- */}
          {privacy.map((priv, index) => {
            return (
              <tbody key={index}>
                <td className="priv_title">{priv.title}</td>
                <td className="priv_desc">{priv.description}</td>
                <td className="priv_info">{priv.info}</td>
              </tbody>
            );
          })}
        </table>
        {/* --------------------------------------- */}

        <h1> 2. What We Use Your Data For</h1>
        <p>
          We use your data to do things like provide our Services, communicate
          with you, troubleshoot issues, secure against fraud and abuse, improve
          and update our Services, analyze how people use our Services, serve
          personalized advertising if any, and as required by law or necessary
          for safety and integrity. We retain your data for as long as it is
          needed to serve the purposes for which it was collected.
        </p>
        <p>We use the data we collect through your use of the Services to:</p>

        <ul className="data_list">
          {data.map((data) => {
            return <li key={data.id}>{data.desc}</li>;
          })}
        </ul>

        {/* ----------------------------------------- */}
        <h1>3. Security</h1>
        <p>
          We use appropriate security based on the type and sensitivity of data
          being stored. As with any internet-enabled system, there is always a
          risk of unauthorized access, so it’s important to protect your
          password and to contact us if you suspect any unauthorized access to
          your account. Jaex takes appropriate security measures to protect
          against unauthorized access, alteration, disclosure, or destruction of
          your personal data that we collect and store. These measures vary
          based on the type and sensitivity of the data. Unfortunately, however,
          no system can be 100% secured, so we cannot guarantee that
          communications between you and Jaex, the Services, or any information
          provided to us in connection with the data we collect through the
          Services will be free from unauthorized access by third parties. Your
          password is an important part of our security system, and it is your
          responsibility to protect it. You should not share your password with
          any third party, and if you believe your password or account has been
          compromised, you should change it immediately.
        </p>

        {/* ------------------------------------------- */}

        <h1>4. Your Rights</h1>
        <p>
          You have certain rights around the use of your data, including the
          ability to opt out of promotional emails, cookies, and collection of
          your data by certain third parties. You can update or terminate your
          account from within our Services, and can also contact us for
          individual rights requests about your personal data. Parents who
          believe we’ve unintentionally collected personal data about their
          underage child should contact us for help deleting that information.
        </p>
        {/* ----------------------------------------------- */}
        <h1>5. Updates & Contact Info</h1>
        <p>
          When we make a material change to this policy, we’ll notify users via
          email, in-product notice, or another mechanism required by law.
          Changes become effective the day they’re posted. Please contact us via
          email or postal mail with any questions, concerns, or disputes.
        </p>
        {/* ------------------------------------------- */}

        <h2>5.1 Modifications to This Privacy Policy</h2>
        <p>
          From time to time, we may update this Privacy Policy. If we make any
          material change to it, we will notify you via email, through a
          notification posted on the Services, or as required by applicable law.
          We will also include a summary of the key changes. Unless stated
          otherwise, modifications will become effective on the day they are
          posted. As permitted by applicable law, if you continue to use the
          Services after the effective date of any change, then your access
          and/or use will be deemed an acceptance of (and agreement to follow
          and be bound by) the revised Privacy Policy. The revised Privacy
          Policy supersedes all previous Privacy Policies.
        </p>
        <h2>5.2 Interpretation</h2>
        <p>
          Any capitalized terms not defined in this policy are defined as
          specified in Jaex's Terms of Use. Any version of this Privacy Policy
          in a language other than English is provided for convenience. If there
          is any conflict with a non-English version, you agree that the English
          language version will control.
        </p>
      </article>

      <Footer />
    </section>
  );
}

export default Privacy