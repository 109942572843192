import { Link } from "react-router-dom";
import { useGlobalContext } from "../../controller/context_api";
import { ArrowRightAlt } from "@material-ui/icons";
const Homesection2 = () => {
  const { isLogin } = useGlobalContext();
  return (
    <section className="sec2">
      <article className="section2_title">
        <h2 className="section2_txt">
          Perform test drilling and track your progress
        </h2>
        <p className="sec2_desc">
          Your test drilling performance will let you know how ready you are for
          your exams.
        </p>
        <div className="sec2_button">
          {isLogin ? (
            <Link to="/dashboard/test_drilling" className="anchor-button">
              Take a drill{" "}
              <ArrowRightAlt
                className="sec_icon"
                style={{ fontSize: "20px" }}
              />
            </Link>
          ) : (
            <Link to="/login" className="anchor-button">
              Take a drill
              <ArrowRightAlt
                className="sec_icon"
                style={{ fontSize: "20px" }}
              />
            </Link>
          )}
        </div>
      </article>
      {/* ////////////////////////////////////////////////////// */}
      <article className="section2_item">
        <img
          src={"./graphics/drill_stats.png"}
          className="drill_stats"
          alt="drill_stats"
        />
      </article>
    </section>
  );
};

export default Homesection2;
