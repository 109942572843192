import { IconButton } from '@material-ui/core';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import SearchIcon from '@material-ui/icons/Search';
import MenuIcon from '@material-ui/icons/Menu';
import { useRef, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import pages from '../model/pages.json'
import {Link, useNavigate} from 'react-router-dom'

import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import ExploreOutlinedIcon from '@material-ui/icons/ExploreOutlined';
import {useGlobalContext} from '../controller/context_api'
import axios from './axios'

const Mobile = (props)=>{
    const color = props.color;
    
    const history = useNavigate()
const mobile = useRef(null)
const [isopen,setIsopen]=useState(false)
const {isLogin,setIsLoading,setisLogin,setIsmobile}= useGlobalContext()
const Navi=()=>{
    isLogin?history("/dashboard"):history("/login")
}

const showMenu=()=>{
    setIsmobile(true)
}

const handleLogout = async()=>{
  setIsLoading(true)
  await axios.get('/logout',{withCredentials:true}).then((res)=>{
   setisLogin(false)
    setIsLoading(false) 
    history('/login', {replace:true})
     })
}
    return(<nav className="dash_mobile_nav" style={{backgroundColor:`${color}`}}>
    {/* ------------------------------ */}
    
{/* -------------------- */}
    <IconButton className='h_menu' onClick={showMenu}><MenuIcon/></IconButton>
    <div className='h_logo_div'><img src="../../../../graphics/logo1.ico" alt="logo"/></div>
    {/*/ <IconButton className='h_menu_icon'><SearchIcon/></IconButton>
    // <IconButton className='h_menu_icon' onClick={Navi}><AccountCircleOutlinedIcon/></IconButton>*/}

</nav>)
}

export default Mobile