import { Link } from "react-router-dom";
import { useGlobalContext } from "../../controller/context_api";
import Mobilenav from "../mobilenav";

const Hero = () => {
  const { isLogin } = useGlobalContext();

  return (
    <section className="hero2">
      <div className="hero_video_cont">
        <video
          autoPlay={"autoplay"}
          loop
          muted
          className="hero_video"
          src="../../../graphics/jaex-bg.mp4"
        ></video>
      </div>
      <div className="hero2_content">
        <Mobilenav color={"rgb(1,1,26,0.8)"} />
        {/* ------------------------------- */}
        <div className="hero2_txt">
          <h1 className="hero2_h1">Quick Solution to Jamb exam preparation</h1>
          <p className="hero2_h4">
            Developed for science students, with over 300+ topics to learn from
          </p>
          <div className="hero2_txt_botton">
            {isLogin ? (
              <Link to="/dashboard">Get Started</Link>
            ) : (
              <Link to="/login">Get Started</Link>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;

// https://firebasestorage.googleapis.com/v0/b/jaex-b9931.appspot.com/o/video%2Fjaex_bg.mp4?alt=media&token=dd37d0bd-1309-4d55-af0c-42a8ae1686ad

// const attemptPlay = () => {
//     videoEl &&
//       videoEl.current &&
//       videoEl.current.play().catch(error => {
//         console.error("Error attempting to play", error);
//       });
//   };

//   useEffect(() => {
//     attemptPlay();
//   }, []);

//   return (
//     <div className="App">
//       <h1>Autoplay example</h1>
//       <div>
//         <video
//           style={{ maxWidth: "100%", width: "800px", margin: "0 auto" }}
//           playsInline
//           loop
//           muted
//           controls
//           alt="All the devices"
//           src="https://stream.mux.com/6fiGM5ChLz8T66ZZiuzk1KZuIKX8zJz00/medium.mp4"
//           ref={videoEl}
//         />
//       </div>
//     </div>
//   );
// }
