
import { AttachFile, CloudUploadOutlined, ExpandMore } from "@material-ui/icons"
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { useEffect, useRef, useState } from "react";
import courses from '../../../model/courses.json'
import axios from '../../axios3'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import {storage} from './firebase'
import {v4 as uuid} from 'uuid'
import {ref, uploadBytes, getDownloadURL} from 'firebase/storage'
import { useGlobalContext } from "../../../controller/context_api";





const CmsBook = ()=>{

const {setIsLoading} = useGlobalContext()
const [course,setCourse]=useState('Course')  
const [title,setTitle]=useState('Book title')
const [description,setDescription]=useState('Book description')
const [book_pdf,setBook_pdf]=useState('Select book')
const [book_pdf_file,setBook_pdf_file]=useState('')
const [book_pdf_url,setBook_pdf_url]=useState('')
const [book_artwork,setBook_artwork]=useState('Select artwork')
const [book_artwork_file,setBook_artwork_file]=useState('')
const [book_artwork_url,setBook_artwork_url]=useState('')
const [update, Setupdate]= useState(false)

useEffect(()=>{
setBook_artwork_file(book_artwork_file)
setBook_pdf_file(book_pdf_file)
},[update])

const [err, Seterr]= useState("")
const [iserr, Setiserr]= useState(false)
const [issuccess,setisSuccess] = useState(false)
const [success,setSuccess] = useState('')

useEffect(()=>{
  
 setTimeout(()=>{
  setisSuccess(false)
setSuccess('')
setCourse("Course");
setTitle("Book title");
setDescription("Book description");
setBook_pdf("Select book");
setBook_artwork("Select artwork")
  
 },3000)

return()=>{
  clearTimeout()
}

},[success])



const drop_cont_ref = useRef(null)

const handleOption = (e)=>{
const drop_cont = drop_cont_ref.current;
drop_cont.classList.toggle('cms_show')

}
const handleCourse = (e)=>{
  setCourse(e.target.value)
const drop_cont = drop_cont_ref.current;
 drop_cont.classList.remove('cms_show') 
}


const handleFileInput = (e)=>{
  Setiserr(false)
const name = e.currentTarget.name
if(name == "pdf"){
  const file = e.currentTarget.files[0]
  setBook_pdf(`${file.name.substring(0,20)}...`)
  setBook_pdf_file(file)
Setupdate(!update)

}else if(name == "artwork"){
const file = e.currentTarget.files[0]
  setBook_artwork(`${file.name.substring(0,20)}...`)
  setBook_artwork_file(file)
  Setupdate(!update)
}

}


const handleTextInput = (e)=>{
  Setiserr(false)
const name =  e.currentTarget.name;
switch(name){
  case "title": setTitle(e.currentTarget.value);break;
  case "description": setDescription(e.currentTarget.value);break;
}

  
}

const Uploadbook = async(e)=>{
  e.preventDefault()


if(title==""|| course==""||description==""||book_pdf==""||book_artwork==""){ Setiserr(true);
Seterr("Please provide all input fields")}else
if(title=="Book title"|| course=="Course"||description=="Book description"||book_pdf=="Select book"||book_artwork=="Select artwork"){ Setiserr(true);
Seterr("Please provide correct input fields")}else 
if(book_artwork_file==""||book_pdf_file==""){
   Setiserr(true);
Seterr("Please choose a file")
}
else{
setIsLoading(true)
  
const bookpdfref = ref(storage, `jeax_books/${"jaex_book_" + uuid()}`)
const bookartworkref = ref(storage, `jeax_book_artworks/${"jaex_bookartwork_" + uuid()}`)

uploadBytes(bookpdfref, book_pdf_file).then(()=>{
  getDownloadURL(bookpdfref).then((url)=>{
    let pdfurl = url;

    uploadBytes(bookartworkref, book_artwork_file).then(()=>{
  getDownloadURL(bookartworkref).then(async(url)=>{ 
    let artworkurl = url
  // ////////upload to mongodb

await axios.post("/books",{
  artworkurl:artworkurl,
  fileurl:pdfurl ,
  title:title,
  description:description,
  subject: course
}).then((res)=>{
  setSuccess(res.data.msg)
  setisSuccess(true)
  setIsLoading(false)
}).catch((err)=>{
  Setiserr(true)
  Seterr(err.response.data)
  setIsLoading(false)
})


  })
})
  })
})


}

}


    return(
        <article className='cms_videoform_cont'>
          <div className='cms_header'>Books</div> 
          {/* ---------------error------------------------- */}
            <div className='err_div'>{iserr&&<article className='error'><ErrorOutlineIcon/> {err}</article>}</div>
            <div className='err_div'>{issuccess&&<article className='success'><CheckCircleOutlineIcon/> {success}</article>}</div>
           {/* ------------------------------------- */}
          <form className='cms_form' onSubmit={Uploadbook}>
            <div className='cms_form_div'>
              <div className='cms_form_content'>{book_pdf}</div>
              <label htmlFor="pdf" className='cms_label'>
                <AttachFile/> 
            <input type='file' name='pdf' id="pdf" onChange={handleFileInput}/>
              </label>  
            </div>
            {/* ------------------------------------------ */}
            <div className='cms_form_div'>
              <div className='cms_form_content'>{book_artwork}</div>
              <label htmlFor="artwork" className='cms_label'>
                <AttachFile/> 
            <input type='file' name='artwork' id="artwork" onChange={handleFileInput}/>
              </label>  
            </div>
            {/* ---------------------------------------------- */}
            <div className='cms_form_div'>
              <div className='cms_form_content'>{course}</div>
              <div className='cms_drop' onClick={handleOption}><ExpandMore/></div> 
          <div className='cms_drop_content' ref={drop_cont_ref}>
        <article className='cms_drop_form'>
          {
            courses.map((course,index)=>{
              const {id,name} = course;

              return(
          <div className='cms_drop_form_div' key={id}>
            <input type='radio' name='courses' id={name} value={name} onClick={handleCourse}/>
            <label htmlFor={name}>{name}</label>
          </div> 
          
              )

            })
          }
           

         </article> 
         </div>
            </div>
            {/* ---------------------------------------------- */}
            <div className='cms_form_div'>
              <input type='text' name='title' value={title}
              onChange={handleTextInput}/>
         </div>
{/* ------------------------------------------------------- */}
<div className='cms_form_div'>

  <textarea  name="description"  onChange={handleTextInput} placeholder="Description"  className="single_note" maxLength="500">
    </textarea>
              
         </div>
{/* ------------------------------<input type='text' name='description' value={description}
              onChange={handleTextInput}/>------------------------- */}
     
{/* ------------------------------------------------------- */}
<div className="cms_buttons" onClick={Uploadbook}><ArrowRightAltIcon/>Upload</div>
          </form>

        </article>
    )
}

export default CmsBook
