import { Link } from "react-router-dom";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Wave from "react-wavify";
const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className="footer_ele">
      <div className="wave f_wave">
        <Wave
          fill="rgba(170, 204, 237,0.5)"
          paused={false}
          className="waves"
          style={{ display: "flex" }}
          options={{ height: 35, amplitude: 30, speed: 0.35, point: 3 }}
        />
        <Wave
          fill="rgba(206, 225, 241,0.5)"
          paused={false}
          className="waves"
          style={{ display: "flex" }}
          options={{ height: 30, amplitude: 25, speed: 0.17, point: 4 }}
        />
        <Wave
          fill="rgb(3, 2, 50)"
          paused={false}
          className="waves f_waves"
          style={{ display: "flex" }}
          options={{ height: 60, amplitude: 20, speed: 0.31, point: 3 }}
        />
      </div>
      {/* ------------------------------------------ */}
      <div className="footer_wrapper">
        <div className="footer_copy">
          &copy;{" Jaex technology" + " " + year + " all rights reserved."}{" "}
        </div>
        <div className="footer_links">
          {/* <Link to="/about" className='footer_link'>About us</Link> */}
          <Link to="/privacy" className="footer_link">
            Privacy policy
          </Link>
          <Link to="/terms" className="footer_link">
            Terms of use
          </Link>
        </div>
        <div className="footer_social">
          <a href="">
            <FacebookIcon style={{ fontSize: "17px" }} />
          </a>
          <a href="">
            <TwitterIcon style={{ fontSize: "17px" }} />
          </a>
          <a href="">
            <LinkedInIcon style={{ fontSize: "17px" }} />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
