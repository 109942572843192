import { useGlobalContext } from "../../controller/context_api"
import CMStestimony from './cmsform/cmstestimony'
const Testimony = ()=>{
const {adminposition} = useGlobalContext()

    return(
        <article className="admin_form_component" style={{right:"-300%",transform:`translateX(-${adminposition}%)`}}>
            
            <CMStestimony/>
        </article>
    )
}
export default Testimony  