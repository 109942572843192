import { IconButton } from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import { useGlobalContext } from "../controller/context_api"
import AdminBody from "./admin/adminbody"
import AdminMenu from "./admin/adminmenu"
import Footer from "./footer"
import Preloader from './init_loading'
import MenuIcon from "@material-ui/icons/Menu";
import Adminnav from './admin/adminnav'
import { useState } from "react"
const Administration = ()=>{
const history =useNavigate()
const {isLogin,userroles,isLoading} = useGlobalContext()
const isAdmin = userroles.includes('admin');
const [isopen,setisopen]=useState(false)
//const isAdmin = true
const openMenu =()=>{
    setisopen(true)
}
const closeMenu =()=>{
    setisopen(false)
}

    return (
      <>
        {isAdmin ? (
          <section className="admin_body">
            {isLoading &&<section className='log_loading'><Preloader /></section>}

            {/* -----------------------nav bar */}
            <article className="admin_nav">
              <span className="cms_m_menu"><IconButton className="h_menu" onClick={openMenu}>
                 <MenuIcon />
              </IconButton></span>
              <div className="admin_nav_logo">
                <img src="../../../../graphics/admin_logo.ico" alt="logo" />
              </div>
            </article>
            <Adminnav props={{isopen,setisopen,closeMenu}}/>
            {/* -------------------------------- */}
            <article className="admin_body_cont">
              <AdminMenu />
              <AdminBody />
            </article>

            {/* ---------------------------------- */}
            {/* <Footer /> */}
            {/* -------------------------------- */}
          </section>
        ) : (
          history("/")
        )}
      </>
    );
}

export default Administration