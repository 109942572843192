import Navigation from "./navigation";
import Footer from "./footer";
import { ExpandMore } from "@material-ui/icons";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { IconButton } from "@material-ui/core";
import faqs from "../model/faq.json";
import Mobilenav from "./mobilenav";
import { Helmet } from "react-helmet";
import { useState } from "react";
import axios from "./axios";
const Help = () => {
  const openFaqDrop = (e) => {
    const drop_element = e.currentTarget.parentElement.parentElement.lastChild;
    drop_element.classList.toggle("show_single_dropdown");
  };
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [iserr, setIserr] = useState(false);
  const [issuccess, setIssuccess] = useState(false);
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");

  const handleInput = (e) => {
    setIserr(false);
    setIssuccess(false);
    const key = e.currentTarget.name;
    switch (key) {
      case "email":
        setEmail(e.currentTarget.value);
        break;
      case "description":
        setDescription(e.currentTarget.value);
        break;
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (email == "") {
      setIserr(true);
      setErr("please provide an email");
    } else if (description == "") {
      setIserr(true);
      setErr("please provide a description");
    } else {
      await axios
        .post("/support", {
          email: email,
          description: description,
        })
        .then((res) => {
          setIssuccess(true);
          setSuccess(res.data.msg);
          setTimeout(() => {
            setIssuccess(false);
            setEmail("");
            setDescription("");
          }, 3000);
        })
        .catch((err) => {
          setIserr(true);
          setErr(err.response.data.msg);
        });
    }
  };

  return (
    <section className="help_component">
      <Helmet>
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/jaex-b9931.appspot.com/o/logo%2Fhome_og.png?alt=media&token=4842f49e-696f-4bfd-8bbe-4316d57dcc3a"
        />
        <meta
          name="description"
          content="Find answers to your frequently asked questions"
        />
        <meta
          name="twitter:card"
          content="Find answers to your frequently asked questions"
        />
        <meta
          name="keywords"
          content="jamb, computer based test, exam,cbt, jamb examination"
        />
        <meta property="og:title" content="Help" />
        <meta
          property="og:description"
          content="Find answers to your frequently asked questions."
        />
        <meta
          property="og:image"
          itemprop="image"
          content="https://firebasestorage.googleapis.com/v0/b/jaex-b9931.appspot.com/o/logo%2Fhome_og.png?alt=media&token=4842f49e-696f-4bfd-8bbe-4316d57dcc3a"
        />
        <meta property="og:type" content="website" />

        <title>Help</title>
      </Helmet>
      <Navigation />
      <Mobilenav color={"rgb(255,255,255)"} />
      {/* ---------------------------------- */}
      <article className="help_body">
        <div className="faq_cont">
          <article className="faq_title">
            {"Frequently Asked Questions?(FAQ)"}
          </article>

          {faqs.map((faq) => {
            const { id, title, description } = faq;

            return (
              <article className="single_faq" key={id}>
                <div className="single_faq_title">
                  <h2> {title}</h2>{" "}
                  <div
                    className="drop_button"
                    onClick={openFaqDrop}
                    style={{
                      position: "absolute",
                      top: "5%",
                      cursor: "pointer",
                    }}
                  >
                    <IconButton>
                      <ExpandMore />
                    </IconButton>
                  </div>
                </div>

                <div className="single_faq_dropdown">
                  <article className="single_faq_desc">{description}</article>
                </div>
              </article>
            );
          })}
        </div>
        {/* ---------------------------- */}
        <div className="suggestion_cont">
          {/* ------------------------------------- */}
          {iserr && (
            <div className="err_div">
              <article className="error">
                <ErrorOutlineIcon /> {err}
              </article>
            </div>
          )}
          {issuccess && (
            <div className="err_div">
              <article className="succ">{success}</article>
            </div>
          )}
          {/* -------------------------------- */}
          <article className="suggestion_title">
            Drop a suggestion on how we can improve our service.
          </article>
          <form className="sug_form" onSubmit={sendMessage}>
            <input
              type="text"
              name="email"
              placeholder="Enter your email"
              onChange={handleInput}
            />
            <textarea
              rows="4"
              cols="80"
              name="description"
              maxlength="200"
              onChange={handleInput}
              placeholder="Make a suggestion..."
            ></textarea>

            <div className="sug_button" onClick={sendMessage}>
              Send
            </div>
          </form>
        </div>
      </article>
      <Footer />
    </section>
  );
};

export default Help;
