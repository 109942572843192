import { useGlobalContext } from "../../controller/context_api"
import CMSquestion from './cmsform/cmsquestion'

const Question = ()=>{
const {adminposition} = useGlobalContext()

    return(
        <article className="admin_form_component" style={{right:"-100%",transform:`translateX(-${adminposition}%)`}}>
            <CMSquestion/>
        </article> 
    )
}
export default Question  