import React from "react";

import { IoCloseSharp } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
const Overviewcard = ({ data }) => {
  const htmlQuestion = data?.question;
  return (
    <div className="overview_card">
      <div
        className="question_text q_overview_txt"
        dangerouslySetInnerHTML={{ __html: htmlQuestion }}
      />
      {data.options.map((option, index) => (
        <p key={index} className="overview_option">
          <span className="opt_tag">
            {(index == 0 && "A") ||
              (index == 1 && "B") ||
              (index == 2 && "C") ||
              (index == 3 && "D")}
          </span>
          <span>{option}</span>
        </p>
      ))}
      <p className={data?.answer == data?.option ? "opt_ans" : "opt_ans opt_atm"}>
        <b>Answer</b>
        <span>{data?.answer}</span>
      </p>
    </div>
  );
};

export default Overviewcard;
