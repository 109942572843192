import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Navigation from "./navigation";
import { useGlobalContext } from "../controller/context_api";
import logs from "../model/logs.json";
import Footer from "./footer";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import axios from "./axios";
import Loading from "./init_loading";
import Mobilenav from "./mobilenav";
import { Helmet } from "react-helmet";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
const Login = () => {
  const { setisLogin, setLoginfo, setIsLoading } = useGlobalContext();
  const history = useNavigate();
  const windowHeight = window.innerHeight;
  const [isloading, setisLoading] = useState(false);
  //useEffect(()=>{
  const changeLog = (e) => {
    e.currentTarget.classList.add("change_log_btn");
    //console.log(e.currentTarget,'clicked')
  };

  const rmchangeLog = (e) => {
    e.currentTarget.classList.remove("change_log_btn");
    //console.log(e.currentTarget,'clicked')
  };
  //},[])
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });
  const [emailerr, setEmailerr] = useState("");
  const [passerr, setPasserr] = useState("");
  const [randerr, setRanderr] = useState("");
  const [isemailerr, setIsemailerr] = useState(false);
  const [ispasserr, setIspasserr] = useState(false);
  const [iserr, setIserr] = useState(false);
  const [p_type, setP_type] = useState("password");
  // //////////////////////////////////////////////////////////
  const toggleShow = () => {
    if (p_type == "password") {
      setP_type("text");
      return;
    } else if (p_type == "text") {
      setP_type("password");
      return;
    }
  };
  const handleInput = (e) => {
    setInputs((prevstate) => ({
      ...prevstate,
      [e.target.name]: e.target.value,
    }));
    setIsemailerr(false);
    setIspasserr(false);
    setIserr(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs.email === "") {
      setIsemailerr(true);
      setEmailerr("please provide a valid email");
    } else if (inputs.password === "") {
      setIspasserr(true);
      setPasserr("please provide a password");
    } else {
      setisLoading(true);
      setIserr(false);
      await axios
        .post(
          "/login",
          {
            username: inputs.email.toLowerCase(),
            password: inputs.password,
          },
          { withCredentials: true }
        )
        .then((res) => {
          //console.log(res)
          setLoginfo(res.data);
          setisLoading(false);
          setIsLoading(false);
          setisLogin(true);
          history("/", { replace: true });
        })
        .catch((error) => {
          const result = error.response.data;
          setisLoading(false);
          setRanderr(result);
          setIserr(true);
        });
    }
  };

  return (
    <section className="login_container">
      <Helmet>
        <meta property="og:image" content="../../../graphics/home_og.png" />
        <meta
          name="description"
          content="Login to get access to all our amzing features."
        />
        <meta
          name="twitter:card"
          content="Login to get access to all our amzing features."
        />
        <meta
          name="keywords"
          content="jamb, computer based test, exam,cbt, jamb examination"
        />
        <meta property="og:title" content="Login" />
        <meta
          property="og:description"
          content="Login to get access to all our amzing features."
        />
        {/* <meta
        property="og:image"
        itemprop="image"
        content="https://firebasestorage.googleapis.com/v0/b/jaex-b9931.appspot.com/o/logo%2Fhome_og.png?alt=media&token=4842f49e-696f-4bfd-8bbe-4316d57dcc3a"
      /> */}
        <meta property="og:type" content="website" />

        <title>Login</title>
      </Helmet>
      {/* ///////////////////navigation ////////////////////////////*/}
      <Navigation />
      <Mobilenav color={"rgba(255,255,255)"} />
      {/*////////////////////////////////////////////////////////////*/}
      {isloading && (
        <section className="log_loading">
          <Loading />
        </section>
      )}
      {/* ////////////////////////////////////////////////////// */}
      <article className="login_div">
        {/* //catching errors */}
        {iserr && (
          <div className="err_div">
            <article className="error">
              <ErrorOutlineIcon /> {randerr}
            </article>
          </div>
        )}
        {/* ------------------------------- */}
        <div className="log_title">
          User Login <span></span>
        </div>
        <form className="log_form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="Enter email"
            onChange={handleInput}
            value={inputs.email}
          />
          {isemailerr && (
            <span className="pass_err">
              <ErrorOutlineIcon />
              {emailerr}
            </span>
          )}

          <div className="form_pass">
            <input
              type={p_type}
              name="password"
              id="password"
              placeholder="password"
              onChange={handleInput}
              value={inputs.password}
              maxLength="20"
            />
            <div className="show_pass" onClick={toggleShow}>
              {p_type == "password" ? (
                <VisibilityOffIcon style={{ fontSize: "16px" }} />
              ) : (
                <RemoveRedEyeIcon style={{ fontSize: "16px" }} />
              )}
            </div>
          </div>
          {ispasserr && (
            <span className="pass_err">
              <ErrorOutlineIcon />
              {passerr}
            </span>
          )}

          <Link to="/recover-password" className="recover">
            Recover password
          </Link>

          <button type="submit" onClick={handleSubmit}>
            Sign in
          </button>
          <div className="reg">
            Not registered yet? <Link to="/signup">Create an account</Link>
          </div>
        </form>
        <div className="o_log">
          <article className="hr"> or continue with</article>
          <article className="log_button">
            {logs.map((log) => {
              const { id, icon } = log;

              return (
                <div
                  className="log_btn"
                  key={id}
                  onMouseOver={changeLog}
                  onMouseOut={rmchangeLog}
                >
                  <img src={icon} alt="log_icon" className="log_image" />
                </div>
              );
            })}
          </article>
        </div>
      </article>

      <Footer />
    </section>
  );
};

export default Login;
