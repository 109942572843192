import { useMemo, useState } from 'react'
import {Link} from 'react-router-dom'
import { useGlobalContext } from "../controller/context_api"
import pages from '../model/pages.json'
import {Default, Logged } from './actions'
const Navigation = ()=>{
 

    const {handleSubmit, isLogin, loginfo} = useGlobalContext()

    const [isAuth,setisAuth]=useState(isLogin)  
 
useMemo(()=>{
  if(loginfo !== ""){
   setisAuth(true); 
  }
   },[loginfo])


    return ( 
        <article className='navigation_div' style={{background:"rgb(1,1,26)"}}>
        <div className='logo_div'><img src={'../../../../graphics/logo1.ico'} alt='logo' className='nav_logo'/></div>
        {/* //////////search div////////// */}
        <div className='search_div'>
          <form onSubmit={handleSubmit}>
            <input type="search" name="search"  placeholder="search for your favorite topic..."/> 
        </form>
        </div> 
        {/* ////////navigation list//////////// */}
        <div className='nav_div' >
          <ul>{
              pages.map((item)=>{
               const {id,route,name} = item;
               return ( <li key={id}><Link to={route}>{name}</Link></li>) 

              })
          }
          </ul>
        </div>
        {/* /////////login/signup/ dashboard button/////////// */}
        <div className='action_div'>
          {isAuth?<Logged/>:<Default/>}
        </div>
        </article> 
    )
}

export default Navigation;