import { useState, useEffect, useRef, useMemo } from "react";
import Navigation from "../navigation";
import dashmenu from "../../model/dashmenu.json";
import DashbordMenu from "./dashboard_menu";
import QuestionGroup1 from "./testQuestions/questionGroup1";
import QuestionGroup2 from "./testQuestions/questionGroup2";
import QuestionGroup3 from "./testQuestions/questionGroup3";
import QuestionGroup4 from "./testQuestions/questionGroup4";
import QuestionGroup5 from "./testQuestions/questionGroup5";
import QuestionGroup6 from "./testQuestions/questionGroup6";
import QuestionGroup7 from "./testQuestions/questionGroup7";
import QuestionGroup8 from "./testQuestions/questionGroup8";
import QuestionGroup9 from "./testQuestions/questionGroup9";
import QuestionGroup10 from "./testQuestions/questionGroup10";

import { ChevronLeft } from "@material-ui/icons";
import { useGlobalContext } from "../../controller/context_api";
import { useNavigate } from "react-router-dom";
import Loading from "../loading";
import Modal from "./testQuestions/defaultModal";
import Emergency from "./testQuestions/emergency";
import Dashmenu from "../../views/mobile_dashnav";
import Dashdrop from "../../views/dashdrop";
import TestOverview from "./testQuestions/textoverview";

const TestDashboard = () => {
  const years = [2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023];
  const {
    questions,
    islogin,
    isLoading,
    totalScore,
    SubmitDrill,
    StartDrill,
    isstart,
    isfinish,
    isemergency,
    translateposition,
    hour,
    minutes,
    seconds,
    issubscribed,
    exam_year,
    setExam_year,
    counter,
    submitted,
    setSubmitted,
  } = useGlobalContext();
  const history = useNavigate();
  // /////////getting the top of the questions parent
  const q_body = useRef(null);

  // const [setisLoading]= useState(true) //remove this and add it back to useglobal

  const dropYears = (e) => {
    e.currentTarget.parentElement.children[2].classList.toggle("show_years");
  };
  const selectYear = (e) => {
    e.currentTarget.parentElement.classList.remove("show_years");
    setExam_year(e.currentTarget.dataset.id);
  };
  // ----------------------------------------
  useEffect(() => {
    const question_body = q_body.current;
    question_body?.scrollTo(0, 0);
  }, [translateposition]);

  const [isauth, setIsauth] = useState(false);
  useMemo(() => {
    if (issubscribed !== false) {
      setIsauth(true);
    }
  }, [issubscribed]);

  useEffect(() => {
    if (isauth) {
    } else {
      history("/dashboard", { replace: true });
    }
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <section className="dash_container">
          <Navigation />
          <Dashmenu color={"rgb(1,1,26)"} />
          <Dashdrop />
          {isemergency && <Emergency />}
          {submitted && <TestOverview />}
          {/* ////////////////////////////////////////////////////////////////// */}
          <article className="dash_body">
            <DashbordMenu />
            {/* /////////////////right content/////////////////////////////////// */}
            <div className="dash_l_content test_row">
              <article className="test_control">
                <div className="test_action_cont">
                  {/* -----------------select year */}
                  <div className="test_year">
                    <span>Exam year: {exam_year}</span>{" "}
                    <span onClick={dropYears}>
                      <ChevronLeft />
                    </span>
                    <ul className="years_drop">
                      {years.map((year, index) => (
                        <li key={index} data-id={year} onClick={selectYear}>
                          {year}
                        </li>
                      ))}
                    </ul>
                    {isstart && <div className="year_disabled"></div>}
                  </div>
                  {/* --------------------------- */}
                  <article className="test_st">
                    {isstart ? (
                      <div className="test_start notactive">Start </div>
                    ) : (
                      <div className="test_start" onClick={StartDrill}>
                        Start{" "}
                      </div>
                    )}

                    {isfinish ? (
                      <div className="test_submit notactive">Finish/submit</div>
                    ) : (
                      <div className="test_submit" onClick={SubmitDrill}>
                        Finish/submit
                      </div>
                    )}
                  </article>

                  <article className="test_ts">
                    <div className="test_time">
                      Count down:{" "}
                      <span>
                        {hour}:{minutes}:{seconds}
                      </span>
                    </div>
                    <div className="test_score">
                      Score: <span>{totalScore}</span>
                    </div>
                  </article>
                </div>
                {/* /////////////////////////////// */}
                <div className="test_action_questions">
                  {questions.map((questions, index) => {
                    return (
                      <div
                        className={
                          index <= counter
                            ? "single_question_n active_q"
                            : "single_question_n"
                        }
                        key={index}
                      >
                        {index + 1}
                      </div>
                    );
                  })}
                </div>
              </article>
              {/* ///////////////////////// */}
              <article className="test_question_cont_body" ref={q_body}>
                {isstart ? (
                  <div className="test_question_cont">
                    <QuestionGroup1 />
                    {/* <QuestionGroup2 />
                    <QuestionGroup3 />
                    <QuestionGroup4 />
                    <QuestionGroup5 />
                    <QuestionGroup6 />
                    <QuestionGroup7 />
                    <QuestionGroup8 />
                    <QuestionGroup9 />
                    <QuestionGroup10 /> */}
                  </div>
                ) : (
                  <Modal />
                )}
              </article>
            </div>
          </article>
        </section>
      )}
    </>
  );
};

export default TestDashboard;

// {
//     questions.map((questions, index)=>{
//     const {q} = questions;
//     let questiongroup1 = index==0|index==1|index==2|index==3|index==4
//     |index==5|index==6|index==7|index==8|index==9

//     if(questiongroup1){
//       return (
//           <div className='single_question' key={index}>

//             </div>
//       ) }
//     })
// }
