// import testimony from '../../model/testimonies.json'
import {useEffect, useState,useMemo} from 'react'
import {Avatar} from '@material-ui/core'
import { useGlobalContext } from '../../controller/context_api'

const HomeSection =()=>{
const [position, Setposition]= useState(0)
const [location, Setlocation]= useState(0)
const [ts_length, Setts_length]= useState(9)
const {testimonies} = useGlobalContext()

// useMemo(()=>{
//     Setts_length(testimonies.length)
// },[testimonies])

// console.log(testimonies)
const Slide = ()=>{
let counter = position;
setInterval(()=>{
   counter++ 
   if(counter == ts_length){
//    Setposition(0); 
   counter = 0
   Setposition(counter);
   }else{Setposition(counter);}


},4000)
}
const SlideM = ()=>{
let counter = location;
console.log(ts_length)
setInterval(()=>{
   counter++ 

   if (counter == ts_length) {
     //    Setposition(0);
     counter = 0;
     Setlocation(counter);
   } else {
     Setlocation(counter);
   }


},4000)
}
 useEffect(()=>{Slide();SlideM()},[])      


    return(
        <section className="sec_5">
            <article className="sec_5_heading">
                Testimonies
            </article>
    {/*------------------------------------------------------------ */}
<article className="testimony_container">
{
testimonies?.map((testimony, index)=>{
const posi =position*75;

    return(<div className="single_testimony" style={{left:`${index*26}%`,
transform: `translateX(-${posi}%)`}} key={index}>
    <div className='testimony_txt'> <span>"</span>
    {testimony?.description}
    </div>
    <div className="testimony_txt1"> Agregate:<span>{testimony?.agregate}</span></div>
    <div className="testimony_avatar"><Avatar src="../../../graphics/avatar.ico"/></div>
    <div className='testimony_txt2'>~Anonymous</div>

</div>)
})

}


</article>
{/* -------------------------------- */}

<article className="testimony_container2">
{
testimonies?.map((testimony, index)=>{
const posi =location*100;
console.log(posi)

    return(<div className="single_testimony2" style={{left:`${index*100}%`,
transform: `translateX(-${posi}%)`}} key={index}>
    <div className='testimony_txt'> <span>"</span>
    {testimony?.description}
    </div>
    <div className="testimony_txt1"> Agregate:<span>{testimony?.agregate}</span></div>
    <div className="testimony_avatar"><Avatar src="../../../graphics/avatar.ico"/></div>
    <div className='testimony_txt2'>~Anonymous</div>

</div>)
}) 

}


</article>
        </section>
    )
}


export default HomeSection