import { useGlobalContext } from "../../controller/context_api"
import CMScutoff from './cmsform/cmscutoff'

const Cutoff = ()=>{
const {adminposition} = useGlobalContext()
    return(
        <article className="admin_form_component" style={{right:"-500%",transform:`translateX(-${adminposition}%)`}}>
           <CMScutoff/>
        </article>
    )
}
export default Cutoff  