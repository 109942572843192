import { Link } from "react-router-dom";
import article from "./article.json";
import { useGlobalContext } from "../../controller/context_api";

const Homesection1 = () => {
  const { isLogin } = useGlobalContext();
  return (
    <section className="sec1">
      {article.map((item) => {
        const { id, icon, name, link, h1, h2, bg_img } = item;
        return (
          <article className="sec1_item" key={id}>
            {/* //////////setting up background image */}
            <div
              className="section_head"
              style={{
                background: `url(${bg_img})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                objectFit: "cover",
              }}
            >
              {/* ///////////////////setting up icon background//////// */}
              <div
                className="section_icon"
                style={{
                  background: `url(${icon})`,
                  backgroundSize: "100% 100%",
                  backgroundRepeat: "no-repeat",
                }}
              ></div>
            </div>
            <div className="section_body">
              <h1 className="header_one">{h1}</h1>
              <p className="header_two">{h2}</p>
            </div>
            <div className="section_btn">
              {isLogin ? (
                <Link to={link}>{name}</Link>
              ) : (
                <Link to="/login">{name}</Link>
              )}
            </div>
          </article>
        );
      })}
    </section>
  );
};

export default Homesection1;
